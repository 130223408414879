import React from 'react'
import { useForm } from 'react-hook-form'
import styled, { useTheme } from 'styled-components'
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip
} from 'recharts'
import { useMedia } from 'react-use'
import { useLocation } from 'wouter'
import {
  Dropdown,
  Checkbox,
  Label,
  FormValue
} from '../../components/common/Form'
import { SPACE, FONT_SIZE, RADIUS } from '../../constants'
import { useSkewData } from '../../hooks/query/amm'
import useTermStore from '../../store/term'
import { useCurrentEthPrice } from '../../hooks/price'
import dayjs from 'dayjs'
import mixpanel from '../../mixpanel'
import { toScaled, toUnscaled } from '../../utils/bn'

type FormData = {
  callExpiry: number
  putExpiry: number
}

const SkewView = () => {
  const { expiry } = useTermStore()
  const setLocation = useLocation()[1]
  const { register, watch } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      callExpiry: expiry,
      putExpiry: expiry
    }
  })

  const fields = watch()
  const isNarrow = useMedia('(max-width: 1060px)')
  const { palette } = useTheme()

  const termData = useSkewData(fields.callExpiry, fields.putExpiry)
  const priceQuery = useCurrentEthPrice()

  if (!termData.isSuccess) {
    return <Container>loading</Container>
  }

  const onClickItem = (e: any, d: any) => {
    console.log(d)
    const key = d.dataKey
    const payload = d.payload

    const path = `/deal/${key === 'iv_call' ? 'eth-call' : 'eth-put'}/${key === 'iv_call' ? payload.call_id : payload.put_id
      }`

    setLocation(path)
    mixpanel.track('click skew item', {
      key,
      id: key === 'iv_call' ? payload.call_id : payload.put_id
    })
  }

  return (
    <Container>
      <OptionSection>
        <CheckboxContainer>
          <Checkbox id="eth-call-check" defaultChecked={true} />
          <Label htmlFor="eth-call-check">ETH-CALL</Label>
        </CheckboxContainer>
        <Dropdown
          {...register('callExpiry', { required: true, valueAsNumber: true })}
        >
          {termData.data.expiries.map(expiry => (
            <option key={expiry} value={expiry}>
              {dayjs.unix(expiry).format('MMM DD')}
            </option>
          ))}
        </Dropdown>

        <CheckboxContainer>
          <Checkbox id="eth-put-check" defaultChecked={true} />
          <Label htmlFor="eth-put-check">ETH-PUT</Label>
        </CheckboxContainer>
        <Dropdown
          {...register('putExpiry', { required: true, valueAsNumber: true })}
        >
          {termData.data.expiries.map(expiry => (
            <option key={expiry} value={expiry}>
              {dayjs.unix(expiry).format('MMM DD')}
            </option>
          ))}
        </Dropdown>
        <HeadItemContainer>
          <Label>ETH Price</Label>
          <HeadValue>
            ${priceQuery.data ? toUnscaled(toScaled(priceQuery.data, 8), 8, 2) : '...'}
          </HeadValue>
        </HeadItemContainer>
      </OptionSection>
      <ChartContainer>
        <ResponsiveContainer width={isNarrow ? '100%' : '80%'} height={400}>
          <LineChart
            data={termData.data.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid stroke={palette.onBackground} />
            <XAxis dataKey="period" stroke={palette.onBackground} />
            <YAxis stroke={palette.onBackground} />
            <Line
              type="linear"
              dataKey="iv_call"
              stroke="#1A81C8"
              strokeWidth={2}
              connectNulls
              activeDot={{
                onClick: onClickItem
              }}
            />
            <Line
              type="linear"
              dataKey="iv_put"
              stroke="#6822F4"
              strokeWidth={2}
              connectNulls
              activeDot={{
                onClick: onClickItem
              }}
            />
            <Tooltip contentStyle={{ visibility: 'hidden' }} />
          </LineChart>
        </ResponsiveContainer>
        <LegendContainer>
          <LegendPanel>
            <LegendItem>
              <LegendColor color="#1A81C8" />
              ETH-CALL VOL Exp {dayjs.unix(fields.callExpiry).format('MMM DD')}
            </LegendItem>
            <LegendItem>
              <LegendColor color="#6822F4" />
              ETH-PUT VOL Exp {dayjs.unix(fields.putExpiry).format('MMM DD')}
            </LegendItem>
          </LegendPanel>
        </LegendContainer>
      </ChartContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: ${SPACE.MEDIUM} 30px;
`

const OptionSection = styled.div`
  display: flex;
  align-items: center;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${SPACE.LARGE};
`

const ChartContainer = styled.div`
  display: flex;
  margin-top: ${SPACE.XXX_LARGE};
  width: 100%;

  @media (max-width: 1060px) {
    flex-direction: column-reverse;
  }
`

const LegendContainer = styled.div`
  width: 20%;
`

const LegendPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 160px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.surface2};
  color: ${({ theme }) => theme.palette.onSurface};
  padding: ${SPACE.MEDIUM} ${SPACE.SMALL};
  font-size: ${FONT_SIZE.X_SMALL};
  border-radius: ${RADIUS.SMALL};
  box-shadow: ${({ theme }) => theme.shadow};

  @media (max-width: 1060px) {
    margin-left: 60px;
    margin-bottom: ${SPACE.MEDIUM};
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.surface};
  }
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`

const LegendColor = styled.span<{ color: string }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
  margin-right: ${SPACE.SMALL};
`

const HeadItemContainer = styled.div`
  margin-right: ${SPACE.SMALL};
  display: flex;
  align-items: center;
`

const HeadValue = styled(FormValue)`
  margin-left: ${SPACE.SMALL};
  padding: 0 12px;
`

export default SkewView
