import React from 'react'
import { BigNumber } from 'ethers'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useTokenAddresses } from '../../hooks/addresses'
import {
  useAllowanceQuery,
  useApproveMutation
} from '../../hooks/query/allowance'
import { Button } from '../common/Button'
import mixpanel from '../../mixpanel'

type Props = {
  size: 'small' | 'medium' | 'large'
  amount: BigNumber
  spender: string
  children: React.ReactElement
}

const ApproveButton: React.FC<Props> = ({
  amount,
  spender,
  size,
  children
}) => {
  const addresses = useTokenAddresses()

  const balanceQuery = useERC20BalanceQuery(addresses.PayingToken)
  const allowanceQuery = useAllowanceQuery(spender, addresses.PayingToken)

  const approve = useApproveMutation()

  const onApprove = async (amount: BigNumber) => {
    await approve.mutateAsync({
      amount,
      address: addresses.PayingToken,
      spender
    })
    mixpanel.track('account approve', {
      amount: amount.toString(),
      address: addresses.PayingToken,
      spender
    })
  }

  return balanceQuery.isSuccess &&
    allowanceQuery.isSuccess &&
    balanceQuery.data.gte(amount) ? (
    allowanceQuery.data.gte(amount) ? (
      children
    ) : (
      <Button
        kind="primary"
        size={size}
        onClick={() => {
          onApprove(amount)
        }}
      >
        Approve USDC
      </Button>
    )
  ) : (
    <Button kind="primary" size={size}>
      Insufficient Balance
    </Button>
  )
}

export default ApproveButton
