import { getImpliedVolatility } from 'implied-volatility'
import greeks from 'greeks'

export function getIV(
  premium: number,
  spot: number,
  strike: number,
  maturity: number,
  isPut: boolean,
  initialIV: number
) {
  return getImpliedVolatility(
    premium,
    spot,
    strike,
    maturity,
    0,
    isPut ? 'put' : 'call',
    initialIV
  )
}

export function getDelta(
  spot: number,
  strike: number,
  maturity: number,
  volatility: number,
  isPut: boolean
) {
  return greeks.getDelta(
    spot,
    strike,
    maturity,
    volatility,
    0,
    isPut ? 'put' : 'call'
  )
}
