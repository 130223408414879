import { BigNumber } from '@ethersproject/bignumber'
import { Position, PositionState } from '../hooks/query/position'

export function isLongActive(p: Position): boolean {
  return p.isITM || p.state !== PositionState.Finalized
}

export function isShortActive(p: {
  amount: BigNumber
  isSettled: boolean
}): boolean {
  return !p.isSettled && p.amount.gt(0)
}
