/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import { Contract, ContractFactory, Overrides } from '@ethersproject/contracts'

import type { MockChainlinkAggregator } from '../MockChainlinkAggregator'

export class MockChainlinkAggregator__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer)
  }

  deploy(overrides?: Overrides): Promise<MockChainlinkAggregator> {
    return super.deploy(overrides || {}) as Promise<MockChainlinkAggregator>
  }
  getDeployTransaction(overrides?: Overrides): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): MockChainlinkAggregator {
    return super.attach(address) as MockChainlinkAggregator
  }
  connect(signer: Signer): MockChainlinkAggregator__factory {
    return super.connect(signer) as MockChainlinkAggregator__factory
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MockChainlinkAggregator {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as MockChainlinkAggregator
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint80',
        name: '',
        type: 'uint80'
      }
    ],
    name: 'getRoundData',
    outputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      },
      {
        internalType: 'uint256',
        name: 'startedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'updatedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint80',
        name: 'answeredInRound',
        type: 'uint80'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'latestRoundData',
    outputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      },
      {
        internalType: 'uint256',
        name: 'startedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'updatedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint80',
        name: 'answeredInRound',
        type: 'uint80'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      }
    ],
    name: 'setLatestRoundData',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

const _bytecode =
  '0x608060405234801561001057600080fd5b506101e9806100206000396000f3fe608060405234801561001057600080fd5b50600436106100415760003560e01c80639a6fc8f5146100465780639f76840a146100c6578063feaf968c14610150575b600080fd5b61008e610054366004610192565b600060208190529081526040902080546001820154600283015460038401546004909401546001600160501b039384169492939192911685565b604080516001600160501b03968716815260208101959095528401929092526060830152909116608082015260a00160405180910390f35b61014e6100d43660046101b3565b6001600160501b039182166000818152602081905260409020805469ffffffffffffffffffff19908116909217808255600180830194855542600280850182815560038087019384558454958a16958816959095179093559554909555549055915460049081559091015460058054919093169116179055565b005b60015460025460035460045460055461008e946001600160501b03908116949392911685565b80356001600160501b038116811461018d57600080fd5b919050565b6000602082840312156101a3578081fd5b6101ac82610176565b9392505050565b600080604083850312156101c5578081fd5b6101ce83610176565b94602093909301359350505056fea164736f6c6343000802000a'
