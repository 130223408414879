import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Router, Route, Redirect, Switch, useLocation } from 'wouter'
import localforage from 'localforage'

import { useEagerConnect } from './hooks/wallet'
import TradeView from './pages/Trade'
import PoolView from './pages/PoolView'
import AccountInfoView from './pages/AccountInfoView'
import AgreementView from './pages/AgreementView'
import DealView from './pages/DealView'
import VaultStatusView from './pages/VaultStatusView'
import SideNav from './components/common/SideNav'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import LoadingIndicator from './components/common/LoadingIndicator'
import { RADIUS } from './constants'

const App = () => {
  const [loaded, setLoaded] = useState(false)
  const setLocation = useLocation()[1]
  useEffect(() => {
    ;(async () => {
      // check residence once. if value is set to true, proceed
      const value = await localforage.getItem('residence')
      if (!value) {
        setLocation('/agreement')
      }
      setLoaded(true)
    })()
    // eslint-disable-next-line
  }, [])

  const tried = useEagerConnect()
  if (!tried || !loaded) return <LoadingIndicator />

  return (
    <>
      <Router>
        <Switch>
          <Route path="/agreement" component={AgreementView} />
          <Route>
            {() => (
              <Container>
                <SideNav />
                <BodyContainer>
                  <Header />
                  <Router>
                    <Switch>
                      <Route path="/">
                        <Redirect to="/trade/eth-call" />
                      </Route>
                      <Route path="/account" component={AccountInfoView} />
                      <Route path="/trade/:option" component={TradeView} />
                      <Route path="/pool/:underlying" component={PoolView} />
                      <Route
                        path="/deal/:option/:seriesId"
                        component={DealView}
                      />
                      <Route path="/status" component={VaultStatusView} />
                    </Switch>
                  </Router>
                </BodyContainer>
              </Container>
            )}
          </Route>
        </Switch>
      </Router>
      <Footer />
    </>
  )
}

const Container = styled.div`
  min-height: calc(100vh - 150px);
  min-width: calc(100vw - 160px);
  margin: 80px 80px 0 80px;
  border-radius: ${RADIUS.LARGE};
  background-color: ${({ theme }) => theme.palette.surface};

  display: flex;
`

const BodyContainer = styled.div`
  width: calc(100% - 180px);
`

export default App
