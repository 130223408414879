import { useQuery } from 'react-query'
import { AggregatorV3Interface__factory } from '../typechain'
import { useAddresses } from './addresses'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { toUnscaled } from '../utils/bn'

export function useCurrentEthPrice() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()

  return useQuery<number>(
    ['eth_price'],
    async () => {
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('Address not loaded')

      const aggregator = AggregatorV3Interface__factory.connect(
        addresses.Aggregator,
        library
      )
      const res = await aggregator.latestRoundData()
      return toUnscaled(res.answer, 8)
    },
    {
      refetchInterval: 15000,
      enabled: !!addresses
    }
  )
}

export function useChartData() {
  return useQuery<Array<{ date: number; price: number }>>(
    ['eth_chart_data'],
    async () => {
      const res = await fetch(
        'https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=30&interval=daily'
      )
      const result = await res.json()
      return result.prices.map(([date, price]: [number, number]) => ({
        date,
        price
      }))
    }
  )
}
