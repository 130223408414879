import { useWeb3React } from '@web3-react/core'
import type { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { SUPPORTED_CHAIN_IDS } from '../constants'

export function useChainId() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const ethereum = (window as any).ethereum
  const [chainId, setChainId] = useState(42)

  useEffect(() => {
    ;(async () => {
      if (!library) {
        if (ethereum) {
          const id = parseInt(ethereum.chainId, 16)
          setChainId(id)
        }
        return
      }
      const result = await library.getNetwork()
      setChainId(result.chainId)
    })()
  }, [library]) // eslint-disable-line

  return chainId
}

export function useIsSupportedChain() {
  const [supported, setSupported] = useState(true)
  const chainId = useChainId()
  useEffect(() => {
    setSupported(SUPPORTED_CHAIN_IDS.includes(chainId))
  }, [chainId])

  return supported
}
