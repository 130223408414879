import create from 'zustand'

interface State {
  strike: number | undefined
  expiry: number | undefined
  setStrike: (strike: number) => void
  setExpiry: (expiry: number) => void
}

const useStore = create<State>(set => ({
  strike: undefined,
  expiry: undefined,
  setStrike: (strike: number) => set({ strike }),
  setExpiry: (expiry: number) => set({ expiry })
}))

export default useStore
