import { BigNumber } from 'ethers'

export function isPut(seriesId: BigNumber) {
  return seriesId.toNumber() % 2 == 0
}

export function getATMStrike(price: number, strikes: number[]) {
  const diffs = strikes.map((s, i) => ({ diff: Math.abs(s - price), i }))

  // Sort by proximity to current price
  diffs.sort((a, b) => (a.diff > b.diff ? 1 : -1))

  return strikes[diffs[0].i]
}

export function getCallPut(value: string): 'call' | 'put' {
  return value.split('-')[1] as 'call' | 'put'
}
