import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Web3ReactProvider } from '@web3-react/core'
import Modal from 'react-modal'
import App from './App'
import ThemeManager from './components/managers/ThemeManager'
import getLibrary from './utils/getLibrary'

Modal.setAppElement('#root')
const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <QueryClientProvider client={queryClient}>
        <ThemeManager>
          <App />
        </ThemeManager>
      </QueryClientProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
