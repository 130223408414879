import { ethers, BigNumber, ContractTransaction } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useMutation, useQuery } from 'react-query'
import { ERC20__factory } from '../../typechain'
import { useChainId, useIsSupportedChain } from '../network'
import { useBlockNumber } from '../blockNumber'

export function useAllowanceQuery(spender: string, address: string) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const blockNumber = useBlockNumber()

  return useQuery(
    ['allowance', account, spender, address, chainId, blockNumber],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!supportedChain) throw new Error('not supported chain')

      const contract = ERC20__factory.connect(address, library)
      return await contract.allowance(account, spender)
    },
    {
      enabled: !!account && !!spender
    }
  )
}

type ApproveParams = {
  amount: BigNumber
  address: string
  spender: string
}

export function useApproveMutation() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()

  return useMutation<ContractTransaction | undefined, unknown, ApproveParams>(
    async ({ address, spender, amount }) => {
      if (!library) throw new Error('not connected')

      const contract = ERC20__factory.connect(address, library.getSigner())
      return await contract.approve(spender, amount)
    }
  )
}
