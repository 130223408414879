import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'wouter'
import useStore, { Tab } from '../../store/tab'
import ConnectButton from './ConnectButton'
import { RADIUS, SPACE } from '../../constants'
import { getPageTitle, getSelectedGroup, Group } from '../../utils/routes'
import NetworkErrorLabel from './NetworkErrorLabel'
import mixpanel from '../../mixpanel'

const Header = () => {
  const [location] = useLocation()
  const title = getPageTitle(location)
  const group = getSelectedGroup(location)
  const { tab, setTab } = useStore()

  return (
    <>
      <Container>
        <HeaderTitle>{title}</HeaderTitle>
        <RightContainer>
          <NetworkErrorLabel />
          <ConnectButton />
        </RightContainer>
      </Container>
      <HeaderBorder />
      {group === Group.Trade && (
        <PageButtonGroup>
          <PageButton
            selected={tab === Tab.Vol}
            onClick={() => {
              setTab(Tab.Vol)
              mixpanel.track('click table tab', {
                tab: 'vol'
              })
            }}
          >
            Vol Table
          </PageButton>
          <PageButton
            selected={tab === Tab.Term}
            onClick={() => {
              setTab(Tab.Term)
              mixpanel.track('click table tab', {
                tab: 'term'
              })
            }}
          >
            Term
          </PageButton>
          <PageButton
            selected={tab === Tab.Skew}
            onClick={() => {
              setTab(Tab.Skew)
              mixpanel.track('click table tab', {
                tab: 'skew'
              })
            }}
          >
            Skew
          </PageButton>
        </PageButtonGroup>
      )}
    </>
  )
}

const Container = styled.header`
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderTitle = styled.h1`
  color: ${({ theme }) => theme.palette.onSurface};
  font-weight: 700;
  margin-left: 30px;
`

const RightContainer = styled.div`
  display: flex;
  margin-right: ${SPACE.MEDIUM};
`

const HeaderBorder = styled.div`
  width: 100%;
  height: 1px;
  background: #1c1c1c;
`

const PageButtonGroup = styled.div`
  margin-left: 30px;
  transform: translateY(-12px);
`

const PageButton = styled.button<{ selected?: boolean }>`
  cursor: pointer;
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary : theme.palette.control};
  color: ${({ theme, selected }) =>
    selected ? theme.palette.onPrimary : theme.palette.onControl};
  border: none;
  border-radius: ${RADIUS.LARGE};
  padding: ${SPACE.SMALL} ${SPACE.LARGE};
  min-width: 60px;
  height: 24px;
  margin-right: ${SPACE.MEDIUM};
`

export default Header
