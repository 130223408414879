import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers, BigNumber } from 'ethers'
import { useAddresses, useTokenAddresses } from '../addresses'
import { AMM__factory, LendingPool__factory, OptionVault__factory } from '../../typechain'
import { Multicall__factory } from '../../typechain/multicall'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { toUnscaled } from '../../utils/bn'
import { useGraphEndpoint } from '../graphEndpoint'

const writtenQuery = `
  query($offset: Int, $limit: Int) {
    writtenEntities(skip: $offset, first: $limit, orderBy: accountId, where:{accountId_gt: 100}) {
      id
      accountId
      seriesId
      recepient
      from
    }
  }
`

export function useVaultStatus(page: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const tokenAddresses = useTokenAddresses()
  const graphEndpoint = useGraphEndpoint()

  return useQuery(
    ['vault_status', page],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses.Vault) throw new Error('address not set')

      const contract = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        library
      )

      const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache()
      })

      const response: {
        data: {
          writtenEntities: {
            accountId: string
            seriesId: string
            recepient: string
          }[]
        }
      } = await client.query({
        query: gql(writtenQuery),
        variables: {
          offset: page * 10,
          limit: 10
        }
      })

      const writtens = await Promise.all(
        response.data.writtenEntities
          .map(e => ({
            accountId: BigNumber.from(e.accountId),
            seriesId: Number(e.seriesId),
            recepient: e.recepient
          }))
          .map(async e => {
            const series = await contract.getOptionSeries(e.seriesId)

            const calls = [
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData('getVault', [
                  e.accountId,
                  series.expiryId
                ])
              },
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData(
                  'getPositionSize',
                  [e.accountId, e.seriesId]
                )
              },
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData('getLiquidatableAmount', [
                  e.accountId,
                  series.seriesId
                ])
              },
            ]

            const result = await multicall.callStatic.tryAggregate(false, calls)

            const vault = contract.interface.decodeFunctionResult(
              'getVault',
              result[0].returnData
            )[0]

            const shortSize = contract.interface.decodeFunctionResult(
              'getPositionSize',
              result[1].returnData
            )[0]

            let liquidatableAmount = null

            if (result[2].success) {
              liquidatableAmount = contract.interface.decodeFunctionResult(
                'getLiquidatableAmount',
                result[2].returnData
              )[0]
            }

            return {
              expiryId: series.expiryId,
              isPut: series.isPut,
              expiry: series.expiry,
              strike: series.strike,
              ...e,
              amount: shortSize,
              collateral: vault.collateral,
              isSettled: vault.isSettled,
              liquidatableAmount
            }
          })
      )

      writtens.sort((a, b) => (a.seriesId > b.seriesId ? 1 : -1))

      return writtens.filter(w => w.accountId.gte(100))
    },
    {
      enabled: !!graphEndpoint && !!library
    }
  )
}

export function usePoolVaultStatus(lowerTick: number, upperTick: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const tokenAddresses = useTokenAddresses()

  return useQuery(
    ['pool_vault_status'],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses.Vault) throw new Error('address not set')

      const contract = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )
      const amm = AMM__factory.connect(tokenAddresses.AMM, library)
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        library
      )

      const data = []

      const expirations = await contract.getLiveOptionSerieses()

      for (let accountId = lowerTick; accountId < upperTick; accountId++) {
        for (const expiration of expirations) {
          const vault = await contract.getVault(accountId, expiration.expiryId)
          if (vault.collateral.eq(0) && vault.hedgePosition.eq(0)) continue

          const shortSize = []
          const ivMove = []

          const calls = []

          calls.push({
            target: contract.address,
            callData: contract.interface.encodeFunctionData(
              'calculateVaultDelta',
              [accountId, expiration.expiryId]
            )
          })

          for (const seriesId of expiration.seriesIds) {
            calls.push({
              target: contract.address,
              callData: contract.interface.encodeFunctionData(
                'getPositionSize',
                [accountId, seriesId]
              )
            })
            calls.push({
              target: amm.address,
              callData: amm.interface.encodeFunctionData('getSeriesState', [
                accountId,
                seriesId
              ])
            })
          }

          const result = await multicall.callStatic.tryAggregate(false, calls)

          const vaultDelta = contract.interface.decodeFunctionResult(
            'calculateVaultDelta',
            result[0].returnData
          )[0]

          for (let i = 1; i < result.length; i += 2) {
            const position = contract.interface.decodeFunctionResult(
              'getPositionSize',
              result[i].returnData
            )
            const seriesState = amm.interface.decodeFunctionResult(
              'getSeriesState',
              result[i + 1].returnData
            )

            shortSize.push(toUnscaled(position[0], 8, 2))
            ivMove.push(toUnscaled(seriesState[0].ivMove, 6, 2))
          }

          data.push({
            accountId,
            expiryId: expiration.expiryId,
            vaultDelta,
            shortSize: shortSize,
            ivMove: ivMove,
            ...vault
          })
        }
      }

      return data
    },
    {
      enabled: !!library
    }
  )
}

export function usePoolStatus(lowerTick: number, upperTick: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useQuery(
    ['pool_state'],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses.AMM) throw new Error('address not set')

      const contract = AMM__factory.connect(tokenAddresses.AMM, library)
      const optionVault = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )

      const expirations = await optionVault.getLiveOptionSerieses()

      const ticks = await Promise.all(
        (
          await contract.getTicks(lowerTick, upperTick)
        )
          .map((tick, i) => ({ tickId: lowerTick + i, ...tick }))
          .map(async tick => {
            let cumulativeFee = BigNumber.from(0)

            const collateralValue = await optionVault.getCollateralValueQuote(
              tick.tickId
            )

            for (const expiration of expirations) {
              const profitState = await contract.getProfitState(
                tick.tickId,
                expiration.expiryId
              )

              cumulativeFee = cumulativeFee.add(profitState.cumulativeFee)
            }

            return {
              collateralValue,
              cumulativeFee,
              ...tick
            }
          })
      )

      return ticks
    },
    {
      enabled: !!library
    }
  )
}

export function useHelthFactor() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const tokenAddresses = useTokenAddresses()

  return useQuery(
    ['health_factor'],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses.Vault) throw new Error('address not set')

      const lendingPool = LendingPool__factory.connect(
        addresses.LendingPool,
        library
      )

      const accountInfo = await lendingPool.getUserAccountData(tokenAddresses.Vault)

      return {
        ...accountInfo
      }
    },
    {
      enabled: !!library
    }
  )
}
