const FONT_SIZE = {
  XX_SMALL: '0.58rem',
  X_SMALL: '0.69rem',
  SMALL: '0.83rem',
  MEDIUM: '1rem',
  LARGE: '1.2rem',
  X_LARGE: '1.44rem',
  XX_LARGE: '1.73rem'
} as const

const SPACE = {
  SMALL: '4px',
  MEDIUM: '8px',
  LARGE: '16px',
  X_LARGE: '20px',
  XX_LARGE: '28px',
  XXX_LARGE: '48px'
} as const

const RADIUS = {
  SMALL: '4px',
  MEDIUM: '8px',
  LARGE: '12px',
  ROUND: '100px'
} as const

const OPTION_TYPE = {
  CALL: '0',
  PUT: '1'
} as const

const PERIOD = {
  ONE_DAY: '1',
  ONE_WEEK: '7',
  TWO_WEEK: '14',
  THREE_WEEK: '21',
  FOUR_WEEK: '28'
} as const

const SUPPORTED_CHAIN_IDS = [1, 42, 80001]

const YEAR_IN_SECONDS = 60 * 60 * 24 * 365

// slippage torelance is 1.0%
const SLIPPAGE_TORELANCE = 10

enum MarginType {
  MM = 0,
  IM = 1
}

const TOOLTIPS = {
  expiry: `The expiration is the time at which the options are settled.`,
  pnl: `The unrealized profit or loss of your position, calculated using current mark price provided by Pool.`,
  im: `IM = Required Initial Margin / Current Collateral. Initial Margin is the capital that you need to open new position.`,
  mm: `MM = Required Maintenance Margin / Current collateral. Maintenance Margin is the capital that you need to keep position open.`,
  mark: `Predy protocol's current option price.`,
  margin: ``
} as const

export {
  SLIPPAGE_TORELANCE,
  FONT_SIZE,
  SPACE,
  RADIUS,
  MarginType,
  OPTION_TYPE,
  PERIOD,
  SUPPORTED_CHAIN_IDS,
  YEAR_IN_SECONDS,
  TOOLTIPS
}
