import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useMutation, useQuery } from 'react-query'
import { OptionVault__factory } from '../../typechain'
import { useChainId, useIsSupportedChain } from '../network'
import { useBlockNumber } from '../blockNumber'
import { useTokenAddresses } from '../addresses'

export function useIsApprovedQuery() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const blockNumber = useBlockNumber()

  return useQuery(
    ['is_approved_for_all', account, chainId, blockNumber],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!supportedChain) throw new Error('not supported chain')

      const contract = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )
      return await contract.isApprovedForAll(account, tokenAddresses.AMM)
    },
    {
      enabled: !!account && !!library
    }
  )
}

export function useSetApprovalMutation() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation(async () => {
    if (!library) throw new Error('not connected')

    const contract = OptionVault__factory.connect(
      tokenAddresses.Vault,
      library.getSigner()
    )

    return await contract.setApprovalForAll(tokenAddresses.AMM, true)
  })
}
