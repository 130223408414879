import React, { createContext, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import { ThemeProvider } from 'styled-components'
import localForage from 'localforage'
import themes from '../../styles/theme'
import GlobalStyle from '../../styles/global'
import LoadingIndicator from '../common/LoadingIndicator'

type Props = {
  children: React.ReactElement
}

export type Themes = keyof typeof themes

export const ThemeContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleTheme: () => {},
  themeKind: 'dark'
})

const ThemeManager: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState<Themes>('dark')
  const [loaded, setLoaded] = useState(false)
  const prevTheme = usePrevious(theme)

  useEffect(() => {
    async function checkTheme() {
      if (!loaded) {
        const t = await localForage.getItem<Themes>('theme')

        if (t) setTheme(t)
        setLoaded(true)
      }
    }

    checkTheme()
  }, [theme, loaded])

  useEffect(() => {
    if (prevTheme && prevTheme !== theme) {
      localForage.setItem('theme', theme)
    }
  }, [theme, prevTheme])

  if (!loaded) return <LoadingIndicator />

  return (
    <ThemeContext.Provider
      value={{
        toggleTheme: () => {
          if (theme === 'light') {
            setTheme('dark')
          } else {
            setTheme('light')
          }
        },
        themeKind: theme
      }}
    >
      <ThemeProvider theme={themes[theme]}>
        <GlobalStyle />

        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeManager
