import { BigNumber } from 'ethers'
import { SLIPPAGE_TORELANCE } from '../constants'

export function applySlippageTorelance(premium: BigNumber, isSelling: boolean) {
  if (isSelling) {
    return premium.mul(1000 - SLIPPAGE_TORELANCE).div(1000)
  } else {
    return premium.mul(1000 + SLIPPAGE_TORELANCE).div(1000)
  }
}
