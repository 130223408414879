import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { injected } from '../connectors'
import { GraphEndpoints } from '../graphEndpoints'

export function useGraphEndpoint() {
  const { library, activate } = useWeb3React<ethers.providers.Web3Provider>()

  const [graphEndpoint, setGraphEndpoint] = useState<string>(
    GraphEndpoints[library?.network?.chainId || 1]
  )

  useEffect(() => {
    const handleNetworkChanged = (network: ethers.providers.Network) => {
      setGraphEndpoint(GraphEndpoints[network.chainId])
      activate(injected, undefined, true).catch(error => {
        console.error('Failed to activate after chain changed', error)
      })
    }

    library?.on('network', handleNetworkChanged)
    return () => {
      library?.off('network', handleNetworkChanged)
    }
  }, [library?.network, activate, library])

  return graphEndpoint || ''
}
