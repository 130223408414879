import { BigNumber, BigNumberish } from 'ethers'

export type Range = {
  tickStart: number
  tickEnd: number
}

export function getRange(rangeId: BigNumberish): Range {
  return {
    tickStart: BigNumber.from(rangeId).mod(100).toNumber(),
    tickEnd: BigNumber.from(rangeId).div(100).toNumber()
  }
}

export function getRangeId(
  lower: BigNumberish,
  upper: BigNumberish
): BigNumber {
  return BigNumber.from(upper).mul(100).add(lower)
}
