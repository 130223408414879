type Palette = {
  background: string
  surface: string
  surface2: string
  surface3: string
  primary: string
  secondary: string
  info: string
  border: string
  error: string
  disabled: string
  control: string
  onPrimary: string
  onSecondary: string
  onBackground: string
  onSurface: string
  onControl: string
  onError: string
  onDisabled: string
  onInfo: string
}

export type Theme = {
  palette: Palette
  shadow: string
}

const themes: {
  light: Theme
  dark: Theme
} = {
  light: {
    palette: {
      background: '#F8F9FF',
      surface: '#FFFFFF',
      surface2: '#FFFFFF',
      surface3: '#FFFFFF',
      error: '#ff0230',
      primary: '#9473d4',
      secondary: '#c9e977',
      info: '#c9e977',
      border: 'gray',
      disabled: 'gray',
      control: '#e8dcff',
      onBackground: '#000000',
      onSurface: '#000000',
      onError: '#FFFFFF',
      onControl: '#FFFFFF',
      onPrimary: '#FFFFFF',
      onSecondary: '#000000',
      onDisabled: '#FFFFFF',
      onInfo: '#FFFFFF'
    },
    shadow: '0 2px 4px rgba(0,0,0,0.25)'
  },
  dark: {
    palette: {
      background: '#2D2D2D',
      surface: '#383838',
      surface2: '#3E3E3E',
      surface3: '#252525',
      error: '#EB7171',
      primary: '#FFD43A',
      secondary: '#6557A4',
      info: '#65AF58',
      border: '#1C1C1C',
      disabled: 'gray',
      control: '#1C1C1C',
      onBackground: '#9E9FA1',
      onSurface: '#FFFFFF',
      onError: '#FFFFFF',
      onControl: '#FFFFFF',
      onPrimary: '#1E1951',
      onSecondary: '#FFFFFF',
      onDisabled: '#FFFFFF',
      onInfo: '#FFFFFF'
    },
    shadow: '0 2px 4px rgba(0,0,0,0.25)'
  }
}

export default themes
