import { useWeb3React } from '@web3-react/core'
import React from 'react'
import styled from 'styled-components'
import { FONT_SIZE, RADIUS, SPACE } from '../../constants'
import { useIsSupportedChain } from '../../hooks/network'

const NetworkErrorLabel = () => {
  const { error } = useWeb3React()
  const supported = useIsSupportedChain()
  if (!error && supported) return null

  return <StyledLabel>This network is not supported</StyledLabel>
}

const StyledLabel = styled.p`
  font-size: ${FONT_SIZE.SMALL};
  background-color: ${({ theme }) => theme.palette.error};
  color: ${({ theme }) => theme.palette.onError};
  padding: ${SPACE.MEDIUM} ${SPACE.SMALL};
  margin-right: ${SPACE.MEDIUM};
  border-radius: ${RADIUS.MEDIUM};
`

export default NetworkErrorLabel
