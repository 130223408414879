import { useMutation, useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers, BigNumber } from 'ethers'
import { useAddresses, useTokenAddresses } from '../addresses'
import {
  AMM,
  AMM__factory,
  OptionVault__factory,
  PriceOracle__factory
} from '../../typechain'
import { Multicall__factory } from '../../typechain/multicall'
import { MarginType } from '../../constants'
import { useCurrentEthPrice } from '../price'
import { toUnscaled } from '../../utils/bn'
import { useChainId, useIsSupportedChain } from '../network'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import dayjs from 'dayjs'
import { applySlippageTorelance } from '../../utils/slippage'
import { useGraphEndpoint } from '../graphEndpoint'

/**
 * the state definition of position
 * 1. live means the option series is live
 * 2. pending means that the option series has been expired but expiry price has not submitted
 * 3. expiry price has submitted and dispute period has passed
 */
export enum PositionState {
  Live,
  Pending,
  Finalized
}

export type Position = {
  isPut: boolean
  expiry: BigNumber
  strike: BigNumber
  seriesId: number
  amount: BigNumber
  premium: BigNumber
  minPremium: BigNumber
  pnl: number
  expiryPrice: BigNumber
  isITM: boolean
  state: PositionState
}

async function calPremium(
  amm: AMM,
  seriesId: number,
  size: BigNumber,
  isSelling: boolean
) {
  let premium = BigNumber.from(0)

  try {
    premium = await amm.calculatePremium(seriesId, size, isSelling)
  } catch (e) {
    premium = BigNumber.from(0)
  }

  return premium
}

function calPayout(
  spot: BigNumber,
  strike: BigNumber,
  size: BigNumber,
  isPut: boolean
) {
  if (isPut === false && spot.gt(strike)) {
    return size.mul(spot.sub(strike)).div('10000000000')
  }

  if (isPut === true && spot.lt(strike)) {
    return size.mul(strike.sub(spot)).div('10000000000')
  }

  return BigNumber.from('0')
}

// 1 days
const ONE_DAY = 60 * 60 * 24

function getCurrentTimestamp() {
  return dayjs().unix()
}

const optionBoughtQuery = `
  query($buyer: String) {
    optionBoughtEntities(where: {buyer: $buyer}) {
      id
      seriesId
      buyer
      amount
      premium
    }
  }
`

const optionBalanceQuery = `
  query($owner: String) {
    optionBalanceEntities(where: {owner: $owner}) {
      id
      seriesId
      owner
      balance
    }
  }
`

export function useLongPositions(includeHistory: boolean) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const tokenAddresses = useTokenAddresses()
  const spot = useCurrentEthPrice()
  const graphEndpoint = useGraphEndpoint()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  return useQuery(
    ['longs', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses.Vault) throw new Error('address not set')
      if (!spot.isSuccess) throw new Error('spot price not loaded')

      const contract = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )
      const amm = AMM__factory.connect(tokenAddresses.AMM, library)
      const priceOracle = PriceOracle__factory.connect(
        addresses.PriceOracle,
        library
      )

      const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache()
      })

      const response: {
        data: {
          optionBoughtEntities: {
            seriesId: string
            buyer: string
            amount: string
            premium: string
          }[]
        }
      } = await client.query({
        query: gql(optionBoughtQuery),
        variables: {
          buyer: account.toLowerCase()
        }
      })

      const balanceResponse: {
        data: {
          optionBalanceEntities: {
            balance: string
            seriesId: string
            owner: string
          }[]
        }
      } = await client.query({
        query: gql(optionBalanceQuery),
        variables: {
          owner: account.toLowerCase()
        }
      })

      function getAveragePrice(seriesId: string) {
        const boughts = response.data.optionBoughtEntities.filter(
          e => e.seriesId === seriesId
        )
        const premium = boughts.reduce(
          (acc, e) => acc.add(e.premium),
          BigNumber.from(0)
        )
        const amount = boughts.reduce(
          (acc, e) => acc.add(e.amount),
          BigNumber.from(0)
        )

        return premium.mul('100000000').div(amount)
      }

      const positions: Position[] = await Promise.all(
        balanceResponse.data.optionBalanceEntities.map(async long => {
          const series = await contract.getOptionSeries(long.seriesId)
          const expiryPrice = await priceOracle.getExpiryPrice(
            addresses.Aggregator,
            series.expiry
          )

          const balance = BigNumber.from(long.balance)

          let premium = BigNumber.from(0)
          let state = PositionState.Live
          let isITM = false
          let pnl = BigNumber.from(0)

          if (series.expiry.sub(ONE_DAY).lt(getCurrentTimestamp())) {
            state = PositionState.Pending
          }

          if (expiryPrice._isFinalized) {
            const payout = calPayout(
              expiryPrice.price,
              series.strike,
              balance,
              series.isPut
            )

            isITM = payout.gt(0)
            pnl = payout
            state = PositionState.Finalized
          } else {
            premium = await calPremium(
              amm,
              Number(long.seriesId),
              balance,
              true
            )

            pnl = premium
          }

          pnl = pnl.sub(
            getAveragePrice(long.seriesId).mul(balance).div('100000000')
          )

          return {
            ...series,
            amount: balance,
            seriesId: Number(long.seriesId),
            premium,
            minPremium: applySlippageTorelance(premium, true),
            expiryPrice: expiryPrice.price,
            pnl: toUnscaled(pnl, 6, 2),
            isITM,
            state
          }
        })
      )

      positions.sort((a, b) => (a.seriesId > b.seriesId ? 1 : -1))

      return positions
        .filter(p => p.amount.gt(0))
        .filter(
          p => includeHistory || p.isITM || p.state !== PositionState.Finalized
        )
    },
    {
      enabled:
        !!graphEndpoint &&
        supportedChain &&
        !!library &&
        spot.isSuccess
    }
  )
}

const writtenQuery = `
  query($recepient: String, $from: String) {
    writtenEntities(where: {recepient: $recepient, from: $from}) {
      id
      accountId
      seriesId
      recepient
      from
    }
  }
`

const optionSoldQuery = `
  query($id: String) {
    optionSoldEntity(id: $id) {
      id
      seriesId
      seller
      amount
      premium
    }
  }
`

export function useShortPositions(includeHistory: boolean) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const tokenAddresses = useTokenAddresses()
  const spot = useCurrentEthPrice()
  const graphEndpoint = useGraphEndpoint()
  const chainId = useChainId()

  return useQuery(
    ['shorts', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses.Vault) throw new Error('address not set')
      if (!spot.isSuccess) throw new Error('spot price not loaded')

      const contract = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )
      const amm = AMM__factory.connect(tokenAddresses.AMM, library)
      const priceOracle = PriceOracle__factory.connect(
        addresses.PriceOracle,
        library
      )
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        library
      )

      const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache()
      })

      const response: {
        data: {
          writtenEntities: {
            id: string
            accountId: string
            seriesId: string
            recepient: string
          }[]
        }
      } = await client.query({
        query: gql(writtenQuery),
        variables: {
          recepient: tokenAddresses.Vault.toLowerCase(),
          from: account.toLowerCase()
        }
      })

      const positions = await Promise.all(
        response.data.writtenEntities
          .map(e => ({
            id: e.id,
            accountId: BigNumber.from(e.accountId),
            seriesId: Number(e.seriesId),
            recepient: e.recepient
          }))
          .map(async e => {
            const series = await contract.getOptionSeries(e.seriesId)

            const calls = [
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData('getVault', [
                  e.accountId,
                  series.expiryId
                ])
              },
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData(
                  'getRequiredMargin',
                  [e.accountId, series.expiryId, MarginType.MM]
                )
              },
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData(
                  'getRequiredMargin',
                  [e.accountId, series.expiryId, MarginType.IM]
                )
              },
              {
                target: priceOracle.address,
                callData: priceOracle.interface.encodeFunctionData(
                  'getExpiryPrice',
                  [addresses.Aggregator, series.expiry]
                )
              },
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData(
                  'getPositionSize',
                  [e.accountId, e.seriesId]
                )
              },
              {
                target: contract.address,
                callData: contract.interface.encodeFunctionData('balanceOf', [
                  account,
                  e.seriesId
                ])
              }
            ]

            const result = await multicall.callStatic.tryAggregate(false, calls)

            const vault = contract.interface.decodeFunctionResult(
              'getVault',
              result[0].returnData
            )[0]

            let mm = BigNumber.from(0)
            let im = BigNumber.from(0)
            if (result[1].success && result[2].success) {
              mm = contract.interface.decodeFunctionResult(
                'getRequiredMargin',
                result[1].returnData
              )[0]
              im = contract.interface.decodeFunctionResult(
                'getRequiredMargin',
                result[2].returnData
              )[0]
            }
            const expiryPrice = priceOracle.interface.decodeFunctionResult(
              'getExpiryPrice',
              result[3].returnData
            )
            const shortSize = contract.interface.decodeFunctionResult(
              'getPositionSize',
              result[4].returnData
            )[0]
            const longSize = contract.interface.decodeFunctionResult(
              'balanceOf',
              result[5].returnData
            )[0]

            const response: {
              data: {
                optionSoldEntity: {
                  seriesId: string
                  seller: string
                  amount: string
                  premium: string
                }
              }
            } = await client.query({
              query: gql(optionSoldQuery),
              variables: {
                id: e.id
              }
            })

            let premium = BigNumber.from(0)
            let pnl = BigNumber.from(response.data.optionSoldEntity.premium)
            let state = PositionState.Live
            let payout = BigNumber.from(0)

            if (series.expiry.lt(getCurrentTimestamp())) {
              state = PositionState.Pending
            }

            if (expiryPrice._isFinalized) {
              payout = calPayout(
                expiryPrice.price,
                series.strike,
                shortSize,
                series.isPut
              )
              pnl = pnl.sub(payout)

              state = PositionState.Finalized
            } else {
              premium = await calPremium(amm, e.seriesId, shortSize, false)

              pnl = pnl.sub(premium)
            }

            return {
              ...series,
              ...e,
              amount: shortSize,
              collateral: vault.isSettled ? vault.collateral : vault.collateral.sub(payout),
              isSettled: vault.isSettled,
              im: im,
              mm: mm,
              imRatio: vault.collateral.eq(0)
                ? 0
                : im.mul(100).div(vault.collateral).toNumber(),
              mmRatio: vault.collateral.eq(0)
                ? 0
                : mm.mul(100).div(vault.collateral).toNumber(),
              premium,
              maxPremium: applySlippageTorelance(premium, false),
              pnl: toUnscaled(pnl, 6, 2),
              longSize,
              expiryPrice: expiryPrice.price,
              state
            }
          })
      )

      positions.sort((a, b) => (a.seriesId > b.seriesId ? 1 : -1))

      return positions.filter(p => includeHistory || (!p.isSettled && p.amount.gt(0)))
    },
    {
      enabled: !!library && spot.isSuccess
    }
  )
}

type ClaimParams = {
  seriesId: number
  size: BigNumber
}

export function useClaimMutation() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation(async ({ seriesId, size }: ClaimParams) => {
    if (!tokenAddresses.Vault || !library) throw new Error('not connected')

    const contract = OptionVault__factory.connect(
      tokenAddresses.Vault,
      library.getSigner()
    )
    return await contract.claim(seriesId, size)
  })
}

type DepositParams = {
  accountId: BigNumber
  expiryId: BigNumber
  collateral: BigNumber
}

export function useDepositToVaultMutation() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation(async (params: DepositParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')

    const contract = OptionVault__factory.connect(
      tokenAddresses.Vault,
      library.getSigner()
    )

    return await contract.deposit(
      params.accountId,
      params.expiryId,
      params.collateral
    )
  })
}

type SettleVaultParams = {
  accountId: BigNumber
  expiryId: BigNumber
}

export function useSettleVaultMutation() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation(async (params: SettleVaultParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')

    const contract = OptionVault__factory.connect(
      tokenAddresses.Vault,
      library.getSigner()
    )

    return await contract.settleVault(params.accountId, params.expiryId)
  })
}

type CloseVaultParams = {
  accountId: BigNumber
  seriesId: number
  amount: BigNumber
}

export function useCloseVaultMutation() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation(async (params: CloseVaultParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')

    if (params.amount.eq(0)) throw new Error('amount is 0')

    const contract = OptionVault__factory.connect(
      tokenAddresses.Vault,
      library.getSigner()
    )

    const cRatio = BigNumber.from('1000000')

    await contract.closeShortPosition(
      params.accountId,
      params.seriesId,
      params.amount,
      cRatio
    )
  })
}
