import styled from 'styled-components'
import { FONT_SIZE, RADIUS, SPACE } from '../../constants'

export const Input = styled.input`
  font-size: ${FONT_SIZE.SMALL};
  background-color: ${({ theme }) => theme.palette.control};
  color: ${({ theme }) => theme.palette.onControl};
  border: none;
  border-radius: ${RADIUS.LARGE};
  padding: ${SPACE.SMALL} ${SPACE.MEDIUM};
  height: 24px;
  margin-right: ${SPACE.MEDIUM};
`

export const Dropdown = styled.select`
  font-size: ${FONT_SIZE.SMALL};
  background-color: ${({ theme }) => theme.palette.control};
  color: ${({ theme }) => theme.palette.onControl};
  border: none;
  border-radius: ${RADIUS.LARGE};
  padding: ${SPACE.SMALL} ${SPACE.MEDIUM};
  min-width: 60px;
  height: 24px;
  margin-right: ${SPACE.MEDIUM};
`

export const Label = styled.label`
  color: ${({ theme }) => theme.palette.onSurface};
  font-size: ${FONT_SIZE.SMALL};
`

export const Checkbox = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  appearance: none;
  display: inline-block;
  position: relative;
  padding: 8px;
  border-radius: ${RADIUS.SMALL};
  background-color: ${({ theme }) => theme.palette.onSurface};

  &:active,
  &:checked:active {
    box-shadow: inset 0px 1px 2px ${({ theme }) => theme.palette.secondary};
  }

  &:checked {
    background-color: ${({ theme }) => theme.palette.info};
    color: ${({ theme }) => theme.palette.onInfo};
  }

  &:checked:after {
    content: '\\2714';
    font-size: 11px;
    position: absolute;
    top: 2px;
    left: 3px;
  }
`

export const FormValue = styled.span`
  background-color: ${({ theme }) => theme.palette.control};
  color: ${({ theme }) => theme.palette.onControl};
  border: none;
  border-radius: ${RADIUS.LARGE};
  padding: ${SPACE.SMALL} ${SPACE.MEDIUM};
  height: 24px;
  margin-right: ${SPACE.MEDIUM};
  font-size: ${FONT_SIZE.SMALL};
  display: flex;
  justify-content: center;
  align-items: center;
`
