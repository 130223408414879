import styled from 'styled-components'
import { RADIUS, SPACE } from '../../constants'

export const Section = styled.div`
  color: ${({ theme }) => theme.palette.onSurface};
  background-color: ${({ theme }) => theme.palette.surface3};
  border-radius: ${RADIUS.MEDIUM};
  margin-top: ${SPACE.LARGE};
`

export const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.palette.onPrimary};
  background-color: ${({ theme }) => theme.palette.primary};
  display: inline-block;
  margin-top: ${SPACE.LARGE};
  padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
  border-top-right-radius: ${RADIUS.ROUND};
  border-bottom-right-radius: ${RADIUS.ROUND};
`
