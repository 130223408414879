import create from 'zustand'

export enum Tab {
  Vol,
  Term,
  Skew
}

interface State {
  tab: Tab
  setTab: (tab: Tab) => void
}

const useStore = create<State>(set => ({
  tab: Tab.Vol,
  setTab: (tab: Tab) => set({ tab })
}))

export default useStore
