export type Addresses = {
  Aggregator: string
  FeePool: string
  PriceOracle: string
  OptionVaultFactory: string
  AMMFactory: string
  Multicall2: string
  LendingPool: string
  Liquidation: string
}

export type TokenAddresses = {
  UnderlyingToken: string
  PayingToken: string
  Vault: string
  AMM: string
}

export const kovan = {
  Aggregator: '0x396c5E36DD0a0F5a5D33dae44368D4193f69a1F0',
  FeePool: '0x7ddf1C3398911fe64459162269ECaB50235e1594',
  PriceOracle: '0x6c272999e488af31991e7c9F875f4E93b72901fc',
  OptionVaultFactory: '0xa472E7B6943e652234f1525B134f5f0D6502B6ce',
  AMMFactory: '0x31233b6B4bB44Caa06A9D495552bB7eD8D280B23',
  Multicall2: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  LendingPool: '0xE0fBa4Fc209b4948668006B2bE61711b7f465bAe',
  Liquidation: '0xd4db81D1AA710e2b5C37955B9636B87fAd8dF381'
}

export const kovanTokens = {
  UnderlyingToken: '0xAD5ce863aE3E4E9394Ab43d4ba0D80f419F61789',
  PayingToken: '0xe22da380ee6b445bb8273c81944adeb6e8450422',
  Vault: '0x5A95aB123eAd7513F37a6D3e47CcF8342B2b19B4',
  AMM: '0x560afaFb148B83741e217a148CC9F7C2eaE0A89b'
}

export const mumbai = {
  Aggregator: '0x0715A7794a1dc8e42615F059dD6e406A6594651A',
  FeePool: '0x3EBd54Da5Eae31b95770ef0E191DBa51fcED3411',
  PriceOracle: '0xE3f8E1543E41cAc1C6aAecCf030e1C5538b508DA',
  OptionVaultFactory: '0x67aEba627995a5526083031bBC9658B9E3F3c486',
  AMMFactory: '0x89474C41700AE270CEeCdC4ACdcE9708Dc544997',
  Multicall2: '0xe9939e7Ea7D7fb619Ac57f648Da7B1D425832631',
  LendingPool: '0x9198F13B08E299d85E096929fA9781A1E3d5d827',
  Liquidation: '0x0000000000000000000000000000000000000000'
}

export const mumbaiTokens = {
  UnderlyingToken: '0x3c68ce8504087f89c640d02d133646d98e64ddd9',
  PayingToken: '0x2058a9d7613eee744279e3856ef0eada5fcbaa7e',
  Vault: '0xb3756F0E54B328264c6e9a22F8F1173e8B721cDd',
  AMM: '0x4c80F42f92e81e32FaA1694C9bc07d33753Bf0d8'
}

export const mainnet = {
  Aggregator: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  FeePool: '0x66fBaAd82083716343B9413CAeB77aA13a8053a4',
  PriceOracle: '0x8FeEBd1fD65B6706B2837792C5DFA5B569c4cEA7',
  OptionVaultFactory: '0x73CA51cE0103F0D9d98feF71268b90E74D1d5dc1',
  AMMFactory: '0x65737f365A46CF30b555C9216c8199929e902628',
  Multicall2: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  LendingPool: '0x7d2768de32b0b80b7a3454c06bdac94a69ddc7a9',
  Liquidation: '0xCA3d624d5AB15EA47C2ec6a27bbC35bbd60701be'
}

export const mainnetTokens = {
  UnderlyingToken: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  PayingToken: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  Vault: '0xA73029D664A835F1429D40CD2CC0b785Fd056157',
  AMM: '0x89aBAb94684Af71Ef4501779249a75A87b1E02ce'
}


export const NETWORKS: { [key: number]: Addresses } = {
  [1]: mainnet,
  [42]: kovan,
  [80001]: mumbai
}

export const TOKENS: { [key: number]: TokenAddresses } = {
  [1]: mainnetTokens,
  [42]: kovanTokens,
  [80001]: mumbaiTokens
}
