import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import {
  Link,
  useLocation,
  LinkProps,
  BaseLocationHook,
  LocationHook
} from 'wouter'
import Logo from '../../assets/Logo.png'
import { FONT_SIZE, RADIUS, SPACE } from '../../constants'
import {
  Group,
  Path,
  getSelectedGroup,
  getSelectedPath
} from '../../utils/routes'
import mixpanel from '../../mixpanel'
import { useChainId } from '../../hooks/network'

const TrackedLink = <H extends BaseLocationHook = LocationHook>(
  props: PropsWithChildren<LinkProps<H>>
) => {
  return (
    <Link
      {...props}
      onClick={() =>
        mixpanel.track('click sidenav', {
          link: props.href
        })
      }
    />
  )
}

const SideNav = () => {
  const chainId = useChainId()
  const [location] = useLocation()
  const selectedGroup = getSelectedGroup(location)
  const selectedPath = getSelectedPath(location)

  return (
    <Container>
      <img src={Logo} alt="Logo" width="124px" />
      <div>
        <LinkGroup>
          <LinkTitle href="/account">
            <span>Account Information</span>
            {selectedGroup === Group.AccountInfo && <TitleBorder />}
          </LinkTitle>
        </LinkGroup>
        <LinkGroup>
          <LinkTitle href="/trade/eth-call">
            <span>Trade</span>
            {selectedGroup === Group.Trade && <TitleBorder />}
          </LinkTitle>
          <LinkItem
            selected={selectedPath === Path.TradeETHCall}
            href="/trade/eth-call"
          >
            ETH Call
          </LinkItem>
          <LinkItem
            selected={selectedPath === Path.TradeETHPut}
            href="/trade/eth-put"
          >
            ETH Put
          </LinkItem>
        </LinkGroup>
        {chainId === 1 ? (<div />) : (
          <LinkGroup>
            <LinkTitle href="/pool/eth">
              <span>Pool</span>
              {selectedGroup === Group.Pool && <TitleBorder />}
            </LinkTitle>
            <LinkItem selected={selectedPath === Path.PoolETH} href="/pool/eth">
              ETH
            </LinkItem>
          </LinkGroup>
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  width: 180px;
  color: ${({ theme }) => theme.palette.onSurface};
  background-color: ${({ theme }) => theme.palette.surface2};
  border-top-left-radius: ${RADIUS.LARGE};
  border-bottom-left-radius: ${RADIUS.LARGE};
  padding-top: 35px;
  padding-left: 20px;
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${SPACE.LARGE} 0;
`

const LinkTitle = styled(TrackedLink) <{ selected?: boolean }>`
  font-size: ${FONT_SIZE.SMALL};
  font-weight: 900;
  display: flex;
  align-items: center;
`

const TitleBorder = styled.span`
  background-color: ${({ theme }) => theme.palette.primary};
  height: 8px;
  margin-left: ${SPACE.SMALL};
  border-top-left-radius: ${RADIUS.SMALL};
  border-bottom-left-radius: ${RADIUS.SMALL};
  flex-grow: 1;
`

const LinkItem = styled(TrackedLink) <{ selected?: boolean }>`
  font-size: ${FONT_SIZE.SMALL};
  font-weight: 400;
  margin-top: ${SPACE.LARGE};
  padding-left: ${SPACE.MEDIUM};
  ${({ selected, theme }) =>
    selected ? `color: ${theme.palette.primary}; font-weight: 700;` : ''};
`

export default SideNav
