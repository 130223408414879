import { useMutation, useQuery } from 'react-query'
import dayjs from 'dayjs'
import { useWeb3React } from '@web3-react/core'
import { ethers, BigNumber, BigNumberish, ContractTransaction } from 'ethers'
import { AMM, AMM__factory, OptionVault__factory } from '../../typechain'
import { div, toScaled, toUnscaled } from '../../utils/bn'
import { useAddresses, useTokenAddresses } from '../addresses'
import { useBlockNumber } from '../blockNumber'
import { getDelta, getIV } from '../../utils/bs'
import * as seriesUtils from '../../utils/series'
import { useCurrentEthPrice } from '../price'
import { Multicall__factory } from '../../typechain/multicall'
import { MarginType, YEAR_IN_SECONDS } from '../../constants'
import { getRange } from '../../utils/pool'
import { useChainId, useIsSupportedChain } from '../network'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { applySlippageTorelance } from '../../utils/slippage'
import { useGraphEndpoint } from '../graphEndpoint'

export type OptionSeries = {
  id: BigNumber
  expiryId: BigNumber
  isSuccess: boolean
  expiry: BigNumber
  strike: BigNumber
  isPut: boolean
  iv: number
  premium: number
}

export function useOptionSerieses(isSelling: boolean, isPut: boolean) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const tokenAddresses = useTokenAddresses()
  const spot = useCurrentEthPrice()

  return useQuery(
    ['option_serieses', tokenAddresses.Vault, isSelling, isPut],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')
      if (!spot.data) throw new Error('spot have not been fetched')

      const contract = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )
      const amm = AMM__factory.connect(tokenAddresses.AMM, library)
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        library
      )

      const calculatePremiumCalls = []
      const getOptionSeriesCalls = []

      const block = await library.getBlock('latest')
      const expiration = await contract.getLiveOptionSerieses()

      for (const e of expiration) {
        if (e.expiry.toNumber() - 60 * 60 <= block.timestamp) continue

        for (const s of e.seriesIds) {
          if (isPut !== seriesUtils.isPut(s)) continue
          const size = '100000000'
          const calPremiumCall = amm.interface.encodeFunctionData(
            'calculatePremium',
            [s, size, isSelling]
          )
          calculatePremiumCalls.push({
            target: amm.address,
            callData: calPremiumCall
          })

          const getSeriesCall = contract.interface.encodeFunctionData(
            'getOptionSeries',
            [s]
          )
          getOptionSeriesCalls.push({
            target: contract.address,
            callData: getSeriesCall
          })
        }
      }

      const resultOfCaucluatePremium = await multicall.callStatic.tryAggregate(
        false,
        calculatePremiumCalls
      )
      const premiums = resultOfCaucluatePremium.map(d =>
        d.success
          ? amm.interface.decodeFunctionResult(
            'calculatePremium',
            d.returnData
          )[0]
          : null
      )

      const resultOfGetOptionSeries = await multicall.callStatic.tryAggregate(
        false,
        getOptionSeriesCalls
      )
      const serieses = resultOfGetOptionSeries.map(
        d =>
          contract.interface.decodeFunctionResult(
            'getOptionSeries',
            d.returnData
          )[0]
      )

      const results: OptionSeries[] = []

      serieses.forEach((series, index) => {
        const id = series.seriesId
        const maturity = series.maturity
        const strike = series.strike
        const isPut = series.isPut
        const premium = premiums[index]

        if (premium === null) {
          results.push({
            id,
            isSuccess: false,
            ...series,
            iv: 0,
            premium: 0
          })
          return
        }

        const iv = getIV(
          toUnscaled(premium, 6),
          spot.data,
          toUnscaled(strike, 8),
          maturity.toNumber() / YEAR_IN_SECONDS,
          isPut,
          toUnscaled(series.iv, 8)
        )
        results.push({
          id,
          isSuccess: true,
          ...series,
          iv: Math.floor(iv * 1000) / 10,
          premium: toUnscaled(premium, 6, 2)
        })
      })

      return results
    },
    {
      enabled: !!account && !!library && !!tokenAddresses && spot.isSuccess
    }
  )
}

export enum ValueType {
  Premium,
  IV
}

export enum IVType {
  Sell,
  Mid,
  Buy
}

export function useTradeBoardData(
  valueType: ValueType,
  ivType: IVType,
  isPut: boolean
) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()
  const seriesesQuery = useOptionSerieses(ivType === IVType.Sell, isPut)

  return useQuery(
    ['trade_board', valueType, ivType, isPut],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')
      if (!seriesesQuery.data) throw new Error('series have not been fetched')
      const serieses = seriesesQuery.data.filter(
        series => series.isPut === isPut
      )

      const strikes = Array.from(
        new Set(serieses.map(series => toUnscaled(series.strike, 8)))
      ).sort()
      const expiries = Array.from(
        new Set(serieses.map(series => series.expiry.toNumber()))
      )

      const data = expiries.map(expiry => {
        const data = new Array(strikes.length)
        strikes.forEach((strike, i) => {
          const found = serieses.find(
            series =>
              series.expiry.toNumber() === expiry &&
              toUnscaled(series.strike, 8) === strike
          )

          data[i] = {
            id: found ? found.id.toNumber() : 0,
            iv:
              found && found.isSuccess
                ? valueType == ValueType.IV
                  ? found.iv
                  : found.premium
                : undefined
          }
        })
        return {
          expiration: dayjs.unix(expiry).format('MMM DD'),
          expiryInSeconds: expiry,
          market: 'Predy',
          impFwd: 860.57,
          data
        }
      })

      return {
        strikes,
        data
      }
    },
    {
      enabled:
        !!account && !!library && !!tokenAddresses && !!seriesesQuery.data
    }
  )
}

export function useTermData(callStrike: number, putStrike: number) {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()
  const seriesesQueryCall = useOptionSerieses(false, false)
  const seriesesQueryPut = useOptionSerieses(false, true)

  return useQuery(
    ['term', callStrike, putStrike],
    async () => {
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')
      if (!seriesesQueryCall.data)
        throw new Error('series have not been fetched')
      if (!seriesesQueryPut.data)
        throw new Error('series have not been fetched')

      const callSerieses = seriesesQueryCall.data
      const putSerieses = seriesesQueryPut.data

      const strikes = Array.from(
        new Set(callSerieses.map(series => toUnscaled(series.strike, 8)))
      ).sort()
      const expiries = Array.from(
        new Set(callSerieses.map(series => series.expiry.toNumber()))
      )

      const data = expiries.map(expiry => {
        const foundCall = callSerieses.find(
          series =>
            series.expiry.toNumber() === expiry &&
            toUnscaled(series.strike, 8) === callStrike
        )
        const foundPut = putSerieses.find(
          series =>
            series.expiry.toNumber() === expiry &&
            toUnscaled(series.strike, 8) === putStrike
        )

        const dataCall =
          foundCall && foundCall.isSuccess ? foundCall.iv : undefined
        const dataPut = foundPut && foundPut.isSuccess ? foundPut.iv : undefined

        const dateStr = dayjs.unix(expiry).format('MMM DD')
        return {
          call_id: foundCall?.id.toString(),
          put_id: foundPut?.id.toString(),
          period: dateStr,
          expiration: dateStr,
          iv_call: dataCall,
          iv_put: dataPut
        }
      })

      return {
        strikes,
        expiries,
        data
      }
    },
    {
      enabled:
        !!library &&
        !!tokenAddresses &&
        !!seriesesQueryCall.data &&
        !!seriesesQueryPut.data
    }
  )
}

export function useSkewData(callExpiry: number, putExpiry: number) {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()
  const seriesesQueryCall = useOptionSerieses(false, false)
  const seriesesQueryPut = useOptionSerieses(false, true)

  return useQuery(
    ['skew', callExpiry, putExpiry],
    async () => {
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')
      if (!seriesesQueryCall.data)
        throw new Error('series have not been fetched')
      if (!seriesesQueryPut.data)
        throw new Error('series have not been fetched')

      const callSerieses = seriesesQueryCall.data
      const putSerieses = seriesesQueryPut.data

      const strikes = Array.from(
        new Set(callSerieses.map(series => toUnscaled(series.strike, 8)))
      ).sort()
      const expiries = Array.from(
        new Set(callSerieses.map(series => series.expiry.toNumber()))
      )

      const data = strikes.map(strike => {
        const foundCall = callSerieses.find(
          series =>
            series.expiry.toNumber() === callExpiry &&
            toUnscaled(series.strike, 8) === strike
        )
        const foundPut = putSerieses.find(
          series =>
            series.expiry.toNumber() === putExpiry &&
            toUnscaled(series.strike, 8) === strike
        )

        const dataCall =
          foundCall && foundCall.isSuccess ? foundCall.iv : undefined
        const dataPut = foundPut && foundPut.isSuccess ? foundPut.iv : undefined
        return {
          call_id: foundCall?.id.toString(),
          put_id: foundPut?.id.toString(),
          period: `$${strike}`,
          strike: strike,
          iv_call: dataCall,
          iv_put: dataPut
        }
      })

      return {
        strikes,
        expiries,
        data
      }
    },
    {
      enabled:
        !!library &&
        !!tokenAddresses &&
        !!seriesesQueryCall.data &&
        !!seriesesQueryPut.data
    }
  )
}

export function useDealData(
  seriesId: number | null,
  size: BigNumber,
  isSelling: boolean,
  imRatio: number
) {
  const blockNumber = useBlockNumber()
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()
  const supportedChain = useIsSupportedChain()
  const spot = useCurrentEthPrice()

  return useQuery(
    [
      'deal',
      tokenAddresses.AMM,
      seriesId,
      size,
      isSelling,
      imRatio,
      blockNumber
    ],
    async () => {
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')
      if (!supportedChain) throw new Error('address not set')
      if (!seriesId) throw new Error('seriesId must not be null')
      if (!spot.isSuccess) throw new Error('spot price not loaded')

      const amm = AMM__factory.connect(tokenAddresses.AMM, library)
      const optionVault = OptionVault__factory.connect(
        tokenAddresses.Vault,
        library
      )

      const series = await optionVault.getOptionSeries(seriesId)
      const premium = await amm.calculatePremium(seriesId, size, isSelling)

      let im = BigNumber.from(0)

      if (isSelling) {
        im = await optionVault.calRequiredMarginForASeries(
          seriesId,
          size,
          MarginType.IM
        )
      }

      const delta = getDelta(
        spot.data,
        toUnscaled(series.strike, 8),
        series.maturity.toNumber() / YEAR_IN_SECONDS,
        toUnscaled(series.iv, 8),
        series.isPut
      )
      const iv = getIV(
        toUnscaled(premium.mul('100000000').div(size), 6),
        spot.data,
        toUnscaled(series.strike, 8),
        series.maturity.toNumber() / YEAR_IN_SECONDS,
        series.isPut,
        toUnscaled(series.iv, 8)
      )

      const spotPrice = toScaled(spot.data, 8)
      const isITM = series.isPut
        ? spotPrice.lt(series.strike)
        : series.strike.lt(spotPrice)
      const strikePercentage = spotPrice
        .sub(series.strike)
        .abs()
        .mul('100000000')
        .div(spotPrice)

      const collateral = im.mul(100).div(imRatio)

      return {
        ...series,
        premium,
        iv: toScaled(iv, 8),
        delta: toScaled(delta, 8),
        maxPremium: applySlippageTorelance(premium, false),
        minPremium: applySlippageTorelance(premium, true),
        im,
        collateral,
        maxApproveCollateral: collateral.mul(105).div(100),
        strikePercentage: `${toUnscaled(strikePercentage, 6, 1)} % ${isITM ? 'ITM' : 'OTM'
          }`
      }
    },
    {
      enabled:
        !!library &&
        !!tokenAddresses &&
        !!supportedChain &&
        seriesId !== null &&
        spot.isSuccess,
      retry: false
    }
  )
}

export function usePremium(
  seriesId: BigNumber | null,
  size: number,
  isSelling: boolean
) {
  const blockNumber = useBlockNumber()
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useQuery(
    ['premium', tokenAddresses.AMM, seriesId, size, isSelling, blockNumber],
    async () => {
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')
      if (!size) return 0
      if (!seriesId) return 0

      const contract = AMM__factory.connect(tokenAddresses.AMM, library)
      return await contract.calculatePremium(
        seriesId,
        toScaled(size, 8),
        isSelling
      )
    },
    {
      enabled: !!library && !!tokenAddresses
    }
  )
}

export function usePremiums(
  serieses: Array<{ id: BigNumber }> | undefined,
  size: number | null,
  isSelling: boolean
) {
  const blockNumber = useBlockNumber()
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useQuery(
    ['premiums', tokenAddresses.AMM, serieses, size, isSelling, blockNumber],
    async () => {
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')
      if (!size) return {}
      if (!serieses) return {}

      const contract = AMM__factory.connect(tokenAddresses.AMM, library)
      const result: { [key: string]: BigNumber } = {}

      await Promise.all(
        serieses.map(async series => {
          const premium = await contract.calculatePremium(
            series.id,
            toScaled(size, 8),
            isSelling
          )
          result[series.id.toHexString()] = premium
        })
      )

      return result
    }
  )
}

type BuyParams = {
  seriesId: number
  amount: BigNumber
  maxFee: BigNumberish
}

export function useBuyOptionMutation() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation<ContractTransaction | undefined, unknown, BuyParams>(
    async ({ seriesId, amount, maxFee }) => {
      if (!tokenAddresses.AMM || !library) throw new Error('not connected')

      const contract = AMM__factory.connect(
        tokenAddresses.AMM,
        library.getSigner()
      )

      console.log('buy', seriesId, amount, maxFee)

      return await contract.buy(seriesId, amount, maxFee)
    }
  )
}

type SellParams = {
  seriesId: number
  amount: BigNumber
  minFee: BigNumberish
}

export function useSellOptionMutation() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  // seriesID
  // amount
  return useMutation(async (params: SellParams) => {
    if (!tokenAddresses || !library) throw new Error('not connected')

    const contract = AMM__factory.connect(
      tokenAddresses.AMM,
      library.getSigner()
    )

    return await contract.sell(params.seriesId, params.amount, params.minFee)
  })
}

type MakeShortPositionParams = {
  accountId: number
  seriesId: number
  cRatio: BigNumber
  amount: BigNumber
  minFee: BigNumberish
}

export function useMakeShortPositionMutation() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  // seriesID
  // amount
  return useMutation(async (params: MakeShortPositionParams) => {
    if (!tokenAddresses || !library) throw new Error('not connected')

    const contract = OptionVault__factory.connect(
      tokenAddresses.Vault,
      library.getSigner()
    )

    return await contract.makeShortPosition(
      params.accountId,
      params.seriesId,
      params.cRatio,
      params.amount,
      params.minFee
    )
  })
}

export function useDepositAmount(
  mint: BigNumber,
  lower: number,
  upper: number
) {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useQuery(
    ['deposit_amount', mint, lower, upper],
    async () => {
      if (!library || !tokenAddresses) throw new Error('library not set')

      const contract = AMM__factory.connect(
        tokenAddresses.AMM,
        library.getSigner()
      )

      const ticks = await contract.getTicks(lower, upper)

      const mintPerRange = mint.div(upper - lower)
      let depositAmount = BigNumber.from(0)

      for (let i = lower; i < upper; i++) {
        const tick = ticks[i - lower]
        if (tick.lastSupply.gt(0)) {
          depositAmount = depositAmount.add(
            div(tick.lastBalance, mintPerRange, tick.lastSupply, true)
          )
        } else {
          depositAmount = depositAmount.add(mintPerRange)
        }
      }
      return depositAmount
    },
    {
      enabled: !!library
    }
  )
}

type DepositParams = {
  mint: BigNumber
  maxDeposit: BigNumber
  lower: number
  upper: number
}

export function useDepositMutation() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation(async (params: DepositParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')
    if (!tokenAddresses) throw new Error('Address not set')

    const contract = AMM__factory.connect(
      tokenAddresses.AMM,
      library.getSigner()
    )

    return await contract.deposit(
      params.mint,
      params.maxDeposit,
      params.lower,
      params.upper
    )
  })
}

export function useLiquidityChartData(lowerTick: number, upperTick: number) {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useQuery(
    ['liquidity_chart_data'],
    async () => {
      if (!library) throw new Error('library not set')
      if (!tokenAddresses) throw new Error('address not set')

      const calculateBalance = (
        supply: BigNumber,
        lastBalance: BigNumber,
        lastSupply: BigNumber
      ) => {
        if (lastSupply.eq(0)) {
          return supply
        } else {
          return supply.mul(lastBalance).div(lastSupply)
        }
      }

      const amm = AMM__factory.connect(tokenAddresses.AMM, library)

      const ticks = await amm.getTicks(lowerTick, upperTick)

      return ticks.map((tick, i) => ({
        iv: `${(lowerTick + i) * 10} % - ${(lowerTick + i + 1) * 10} %`,
        liquidity: toUnscaled(
          calculateBalance(tick.supply, tick.lastBalance, tick.lastSupply),
          6
        )
      }))
    },
    {
      enabled: !!library
    }
  )
}

// eslint-disable-next-line
async function getSuppliedRange(
  contract: AMM,
  lowerTick: number,
  upperTick: number
) {
  const ticks = await contract.getTicks(lowerTick, upperTick)

  let tickStart = 20
  let tickEnd = 0

  ticks.forEach((tick, i) => {
    if (tick.supply.gt(0)) {
      const tickId = lowerTick + i
      if (tickStart > tickId) {
        tickStart = tickId
      }
      if (tickEnd < tickId) {
        tickEnd = tickId + 1
      }
    }
  })

  return {
    tickStart,
    tickEnd
  }
}

const poolBalanceQuery = `
  query($owner: Bytes) {
    poolBalanceEntities(where: {owner: $owner}) {
      id
      rangeId
      owner
      balance
    }
  }
`

// eslint-disable-next-line
export function useLiquidityList(lowerTick: number, upperTick: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()
  const graphEndpoint = useGraphEndpoint()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()

  return useQuery(
    ['liquidity_list', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')

      const contract = AMM__factory.connect(tokenAddresses.AMM, library)

      const client = new ApolloClient({
        uri: graphEndpoint,
        cache: new InMemoryCache()
      })

      const response: {
        data: {
          poolBalanceEntities: {
            balance: string
            rangeId: string
            owner: string
          }[]
        }
      } = await client.query({
        query: gql(poolBalanceQuery),
        variables: {
          owner: account.toLowerCase()
        }
      })

      return await Promise.all(
        response.data.poolBalanceEntities
          .map(e => ({
            rangeId: Number(e.rangeId),
            balance: BigNumber.from(e.balance)
          }))
          .filter(d => d.balance.gt(0))
          .map(async d => {
            const rangeId = d.rangeId
            const range = getRange(d.rangeId)
            const tokenAmount = d.balance
            const withdrawableAmount = await contract.getWithdrawableAmount(
              tokenAmount,
              range.tickStart,
              range.tickEnd
            )

            return {
              account,
              rangeId,
              range,
              tokenAmount,
              withdrawableAmount
            }
          })
      )
    },
    {
      enabled: !!graphEndpoint && !!library && supportedChain
    }
  )
}

export function useLockupState() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()
  const supportedChain = useIsSupportedChain()

  return useQuery(
    ['lockup_state'],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')

      const contract = AMM__factory.connect(tokenAddresses.AMM, library)

      const lastProidedAt = await contract.lastProvidedAt(account)

      const lockupPeriod = await contract.getLockupPeriod()

      const timestamp = (await library.getBlock('latest')).timestamp

      const until = lastProidedAt.add(lockupPeriod).toNumber()

      return { isLockedUp: until > timestamp, until }
    },
    {
      enabled: !!account && !!library && supportedChain
    }
  )
}

export function useReserveWithdrawMutation() {
  return useMutation(async () => {
    return true
  })
}

type WithdrawParams = {
  burn: BigNumber
  minWithdrawal: BigNumber
  rangeId: number
  useReservation: boolean
}

export function useWithdrawMutation() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const tokenAddresses = useTokenAddresses()

  return useMutation(async (params: WithdrawParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')
    if (!tokenAddresses) throw new Error('Address not set')

    const contract = AMM__factory.connect(
      tokenAddresses.AMM,
      library.getSigner()
    )

    return await contract.withdraw(
      params.burn,
      params.minWithdrawal,
      params.rangeId,
      params.useReservation
    )
  })
}
