import styled, { DefaultTheme } from 'styled-components'
import { FONT_SIZE, RADIUS, SPACE } from '../../constants'

function getKindColor(theme: DefaultTheme, kind: 'primary' | 'cancel') {
  if (kind === 'primary') return theme.palette.info
  if (kind === 'cancel') return theme.palette.error
}

function getBackgroundColor(
  theme: DefaultTheme,
  kind: 'primary' | 'cancel',
  inverse = false,
  disabled = false
) {
  if (disabled) return theme.palette.disabled
  if (inverse) return 'transparent'
  return getKindColor(theme, kind)
}

function getColor(
  theme: DefaultTheme,
  kind: 'primary' | 'cancel',
  inverse = false,
  disabled = false
) {
  if (disabled) return theme.palette.onDisabled
  if (kind === 'primary' && !inverse) return theme.palette.onInfo
  if (kind === 'cancel' && !inverse) return theme.palette.onInfo
  return getKindColor(theme, kind)
}

function getBorder(
  theme: DefaultTheme,
  kind: 'primary' | 'cancel',
  inverse = false,
  disabled = false
) {
  if (disabled) return theme.palette.disabled
  if (!inverse) return 'none'
  return `solid 1px ${getKindColor(theme, kind)}`
}

export const Button = styled.button<{
  kind: 'primary' | 'cancel'
  inverse?: boolean
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
}>`
  cursor: ${({ disabled }) => (disabled ? `inherit` : `pointer`)};
  border-radius: ${RADIUS.MEDIUM};
  font-size: ${({ size }) =>
    size === 'small' ? FONT_SIZE.X_SMALL : FONT_SIZE.MEDIUM};
  padding: ${({ size }) =>
    size === 'small'
      ? SPACE.SMALL
      : size === 'large'
      ? SPACE.LARGE
      : SPACE.MEDIUM};
  ${({ theme, kind, inverse, disabled }) => `
		background-color: ${getBackgroundColor(theme, kind, inverse, disabled)}};
		color: ${getColor(theme, kind, inverse, disabled)};
		border: ${getBorder(theme, kind, inverse, disabled)};
	`}
`
