import React from 'react'
import useStore, { Tab } from '../../store/tab'
import TableView from './TableView'
import SkewView from './SkewView'
import TermView from './TermView'

const TradeView = () => {
  const { tab } = useStore()
  if (tab === Tab.Skew) return <SkewView />
  if (tab === Tab.Term) return <TermView />
  return <TableView />
}

export default TradeView
