export enum Group {
  AccountInfo,
  Trade,
  Pool,
  Empty
}

export function getSelectedGroup(location: string): Group {
  switch (location.split('/')[1]) {
    case 'trade':
      return Group.Trade
    case 'account':
      return Group.AccountInfo
    case 'pool':
      return Group.Pool
    case 'deal':
      return Group.Trade
    default:
      return Group.Empty
  }
}

export enum Path {
  TradeETHCall,
  TradeETHPut,
  PoolETH,
  Empty
}

export function getSelectedPath(location: string): Path {
  switch (location) {
    case '/trade/eth-call':
      return Path.TradeETHCall
    case '/trade/eth-put':
      return Path.TradeETHPut
    case '/pool/eth':
      return Path.PoolETH
    default:
      return Path.Empty
  }
}

export function getPageTitle(location: string): string {
  switch (location) {
    case '/trade/eth-call':
      return 'ETH Call Option'
    case '/trade/eth-put':
      return 'ETH Put Option'
    case '/pool/eth':
      return 'ETH Pool'
    case '/account':
      return 'Account Information'
    default:
      return ''
  }
}
