import React from 'react'
import styled from 'styled-components'
import { FONT_SIZE } from '../../constants'

const Footer = () => {
  // TODO: add link
  return <Container>&copy; Predy | <a href="https://predy.gitbook.io/predy-documentation/" target="_blank" rel="noopener noreferrer">Document</a> | <a href="https://www.predy.finance/Terms_of_Services.pdf" target="_blank" rel="noopener noreferrer">Terms</a> | <a href="https://www.predy.finance/Privacy_Policy_QA.pdf" target="_blank" rel="noopener noreferrer">Privacy</a></Container>
}

const Container = styled.footer`
  margin: 30px 80px 24px 80px;
  height: 16px;
  font-size: ${FONT_SIZE.SMALL};
  color: ${({ theme }) => theme.palette.onBackground};
`

export default Footer
