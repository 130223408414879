import React, { useState } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { Section, SectionTitle } from '../components/common/Section'
import { Input, Label, FormValue } from '../components/common/Form'
import { Button } from '../components/common/Button'
import Toggle from '../components/common/Toggle'
import { SPACE, FONT_SIZE, RADIUS, TOOLTIPS } from '../constants'
import {
  PositionState,
  useClaimMutation,
  useCloseVaultMutation,
  useDepositToVaultMutation,
  useLongPositions,
  useSettleVaultMutation,
  useShortPositions
} from '../hooks/query/position'
import { toScaled, toUnscaled } from '../utils/bn'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { BigNumber } from 'ethers'
import { useERC20BalanceQuery } from '../hooks/query/balance'
import { useTokenAddresses } from '../hooks/addresses'
import { useAllowanceQuery, useApproveMutation } from '../hooks/query/allowance'
import { useBuyOptionMutation, useSellOptionMutation } from '../hooks/query/amm'
import {
  useIsApprovedQuery,
  useSetApprovalMutation
} from '../hooks/query/approval'
import { useCurrentEthPrice } from '../hooks/price'
import { isLongActive, isShortActive } from '../utils/position'
import ShortIcon from '../assets/short_icon_black.svg'
import LongIcon from '../assets/long_icon_black.svg'
import mixpanel from '../mixpanel'
import ApproveButton from '../components/account/ApproveButton'

type FormData = {
  depositAmount: number
}

const AccountInfoView = () => {
  const addresses = useTokenAddresses()

  const { register, watch } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      depositAmount: 0
    }
  })

  const fields = watch()

  const balanceQuery = useERC20BalanceQuery(addresses.PayingToken)
  const allowanceQuery = useAllowanceQuery(
    addresses.Vault,
    addresses.PayingToken
  )
  const ammAllowanceQuery = useAllowanceQuery(
    addresses.AMM,
    addresses.PayingToken
  )
  const isApproved = useIsApprovedQuery()

  const depositToVault = useDepositToVaultMutation()
  const claim = useClaimMutation()
  const sellOption = useSellOptionMutation()
  const settleVault = useSettleVaultMutation()
  const closeVault = useCloseVaultMutation()
  const buyOption = useBuyOptionMutation()
  const setApproval = useSetApprovalMutation()

  const approve = useApproveMutation()

  const longs = useLongPositions(true)
  const shorts = useShortPositions(true)
  const [depositingIndex, setDepositingIndex] = useState(-1)
  const priceQuery = useCurrentEthPrice()
  const [isActive, setIsActive] = useState(true)

  const onApprove = async (amount: BigNumber, spender: string) => {
    await approve.mutateAsync({
      amount,
      address: addresses.PayingToken,
      spender
    })
    mixpanel.track('account approve', {
      amount: amount.toString(),
      address: addresses.PayingToken,
      spender
    })
  }

  const onSetApproval = async () => {
    await setApproval.mutateAsync()
  }

  const onDeposit = async (accountId: BigNumber, expiryId: BigNumber) => {
    const depositAmount = toScaled(fields.depositAmount, 6)

    if (!allowanceQuery.isSuccess) {
      return
    }

    if (allowanceQuery.data.lt(depositAmount)) {
      return
    }

    await depositToVault.mutateAsync({
      accountId,
      expiryId,
      collateral: depositAmount
    })

    mixpanel.track('account deposit', {
      accountId: accountId.toString(),
      expiryId: expiryId.toString(),
      collateral: depositAmount.toString()
    })
  }

  const onClaim = async (seriesId: number, size: BigNumber) => {
    await claim.mutateAsync({
      seriesId,
      size
    })
    mixpanel.track('account claim', {
      seriesId,
      size: size.toString()
    })
  }

  const onCloseToSell = async (
    seriesId: number,
    amount: BigNumber,
    minFee: BigNumber
  ) => {
    await sellOption.mutateAsync({
      seriesId,
      amount,
      minFee
    })
    mixpanel.track('account sell', {
      seriesId,
      amount: amount.toString(),
      minFee: minFee.toString()
    })
  }

  const onSettle = async (accountId: BigNumber, expiryId: BigNumber) => {
    await settleVault.mutateAsync({
      accountId,
      expiryId
    })

    mixpanel.track('account settle', {
      accountId: accountId.toString(),
      expiryId: expiryId.toString()
    })
  }

  const onCloseToBuy = async (
    seriesId: number,
    amount: BigNumber,
    maxFee: BigNumber
  ) => {
    await buyOption.mutateAsync({
      seriesId,
      amount,
      maxFee
    })

    mixpanel.track('account buy', {
      seriesId,
      amount: amount.toString(),
      maxFee: maxFee.toString()
    })
  }

  const onCloseVault = async (
    accountId: BigNumber,
    seriesId: number,
    amount: BigNumber
  ) => {
    await closeVault.mutateAsync({
      accountId,
      seriesId,
      amount
    })

    mixpanel.track('account close vault', {
      accountId: accountId.toString(),
      seriesId,
      amount: amount.toString()
    })
  }

  return (
    <Container>
      <Head>
        <HeadItemContainer>
          <Label>Underlying</Label>
          <HeadValue>ETH</HeadValue>
        </HeadItemContainer>
        <HeadItemContainer>
          <Label>ETH Price</Label>
          <HeadValue>
            ${priceQuery.data ? Math.floor(priceQuery.data) : '...'}
          </HeadValue>
        </HeadItemContainer>
        <HeadItemContainer>
          <Toggle
            options={[
              { label: 'Active Positions', value: true },
              { label: 'Inactive Positions', value: false }
            ]}
            value={isActive}
            onChange={(value: boolean) => {
              setIsActive(value)
              mixpanel.track('toggle account isActive', {
                value
              })
            }}
          />
        </HeadItemContainer>
      </Head>
      <Section>
        <SectionTitle>
          <Icon src={LongIcon} />
          Longs
        </SectionTitle>
        <SectionBody>
          <List>
            <ListHead>
              <HeaderItem>
                Expiration
                <HelpIcon data-tip={TOOLTIPS.expiry}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem>Strike</HeaderItem>
              <HeaderItem>OptionType</HeaderItem>
              <HeaderItem style={{ width: '40px' }}>Size</HeaderItem>
              <HeaderItem style={{ width: '100px' }}>
                Mark Price
                <HelpIcon data-tip={TOOLTIPS.mark}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem>
                P&L<HelpIcon data-tip={TOOLTIPS.pnl}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem style={{ width: '120px' }}></HeaderItem>
              <HeaderItem style={{ width: '100px' }}></HeaderItem>
              <HeaderItem style={{ width: '100px' }}></HeaderItem>
              <HeaderItem style={{ width: '210px' }}>Actions</HeaderItem>
            </ListHead>
            {longs.isSuccess ? (
              longs.data
                .filter(p => (isActive ? isLongActive(p) : !isLongActive(p)))
                .map((long, i) => (
                  <ListItem key={i}>
                    <ListCell>
                      {dayjs.unix(long.expiry.toNumber()).format('MMM DD')}
                    </ListCell>
                    <ListCell>
                      {toUnscaled(long.strike, 8).toLocaleString()} USD
                    </ListCell>
                    <ListCell>{long.isPut ? 'PUT' : 'CALL'}</ListCell>
                    <ListCell style={{ width: '40px' }}>
                      {toUnscaled(long.amount, 8)}
                    </ListCell>
                    <ListCell style={{ width: '100px' }}>
                      {long.premium.eq(0)
                        ? 'N.A.'
                        : toUnscaled(long.premium.mul('100000000').div(long.amount), 6).toLocaleString()}{' '}
                      USDC
                    </ListCell>
                    <ListCell>
                      {long.premium.eq(0) &&
                        long.state !== PositionState.Finalized
                        ? 'N.A.'
                        : long.pnl}
                    </ListCell>
                    <ListCell style={{ width: '120px' }}></ListCell>
                    <ListCell style={{ width: '100px' }}></ListCell>
                    <ListCell style={{ width: '100px' }}></ListCell>
                    <ListCell style={{ width: '210px' }}>
                      {!isActive ? (
                        <div />
                      ) : long.state === PositionState.Finalized ? (
                        <ClaimButton
                          kind="primary"
                          inverse={!long.isITM}
                          disabled={!long.isITM}
                          size="small"
                          onClick={() => {
                            onClaim(long.seriesId, long.amount)
                          }}
                        >
                          Claim
                        </ClaimButton>
                      ) : long.state === PositionState.Pending ? (
                        <div>Pending</div>
                      ) : isApproved.isSuccess ? (
                        isApproved.data ? (
                          <ClaimButton
                            kind="primary"
                            size="small"
                            inverse={long.premium.eq(0)}
                            onClick={() => {
                              onCloseToSell(
                                long.seriesId,
                                long.amount,
                                long.minPremium
                              )
                            }}
                          >
                            Close to sell
                          </ClaimButton>
                        ) : (
                          <ClaimButton
                            kind="primary"
                            size="small"
                            onClick={() => {
                              onSetApproval()
                            }}
                          >
                            Set approval
                          </ClaimButton>
                        )
                      ) : (
                        <div>Loading</div>
                      )}
                    </ListCell>
                  </ListItem>
                ))
            ) : (
              <div />
            )}
          </List>
        </SectionBody>
      </Section>
      <Section>
        <SectionTitle>
          <Icon src={ShortIcon} />
          Shorts
        </SectionTitle>
        <SectionBody>
          <List>
            <ListHead>
              <HeaderItem>
                Expiration
                <HelpIcon data-tip={TOOLTIPS.expiry}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem>Strike</HeaderItem>
              <HeaderItem>OptionType</HeaderItem>
              <HeaderItem style={{ width: '40px' }}>Size</HeaderItem>
              <HeaderItem style={{ width: '100px' }}>
                Mark Price
                <HelpIcon data-tip={TOOLTIPS.mark}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem>
                P&L<HelpIcon data-tip={TOOLTIPS.pnl}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem style={{ width: '120px' }}>
                Collateral
                <HelpIcon data-tip={TOOLTIPS.margin}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem style={{ width: '100px' }}>
                Initial Margin
                <HelpIcon data-tip={TOOLTIPS.im}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem style={{ width: '100px' }}>
                Maintenance Margin
                <HelpIcon data-tip={TOOLTIPS.mm}>&#9432;</HelpIcon>
              </HeaderItem>
              <HeaderItem style={{ width: '210px' }}>Actions</HeaderItem>
            </ListHead>
            {shorts.isSuccess ? (
              shorts.data
                .filter(p => (isActive ? isShortActive(p) : !isShortActive(p)))
                .map((short, i) => (
                  <ListItem key={i}>
                    <ListCell>
                      {dayjs.unix(short.expiry.toNumber()).format('MMM DD')}
                    </ListCell>
                    <ListCell>
                      {toUnscaled(short.strike, 8).toLocaleString()} USD
                    </ListCell>
                    <ListCell>{short.isPut ? 'PUT' : 'CALL'}</ListCell>
                    <ListCell style={{ width: '40px' }}>
                      {toUnscaled(short.amount, 8)}
                    </ListCell>
                    <ListCell style={{ width: '100px' }}>
                      {short.premium.eq(0)
                        ? '-'
                        : toUnscaled(short.premium.mul('100000000').div(short.amount), 6).toLocaleString()}{' '}
                      USDC
                    </ListCell>
                    <ListCell>
                      {short.premium.eq(0) &&
                        short.state !== PositionState.Finalized
                        ? 'N.A.'
                        : short.pnl}
                    </ListCell>
                    <ListCell style={{ width: '120px' }}>
                      {toUnscaled(short.collateral, 6).toLocaleString()} USD
                    </ListCell>
                    <ListCell style={{ width: '100px' }}>
                      {short.imRatio}%
                    </ListCell>
                    <ListCell style={{ width: '100px' }}>
                      {short.mmRatio}%
                    </ListCell>
                    {!isActive ? (
                      <LargeCell />
                    ) : depositingIndex === i ? (
                      <LargeCell selected>
                        <SelectedCellTitle>
                          Margin(To Be Added)
                        </SelectedCellTitle>
                        <Input
                          {...register('depositAmount', {
                            required: true,
                            valueAsNumber: true
                          })}
                        />{' '}
                        USDC
                        <SelectedMContainer>
                          <SelectedValueContainer>
                            <PrimaryLabel>IM</PrimaryLabel>
                            <SelectedValue>
                              {short.im
                                .mul(100)
                                .div(
                                  short.collateral.add(
                                    toScaled(fields.depositAmount || 0, 6)
                                  )
                                )
                                .toNumber()}
                              %
                            </SelectedValue>
                          </SelectedValueContainer>
                          <SelectedValueContainer>
                            <PrimaryLabel>MM</PrimaryLabel>
                            <SelectedValue>
                              {short.mm
                                .mul(100)
                                .div(
                                  short.collateral.add(
                                    toScaled(fields.depositAmount || 0, 6)
                                  )
                                )
                                .toNumber()}
                              %
                            </SelectedValue>
                          </SelectedValueContainer>
                        </SelectedMContainer>
                        <ButtonContainer>
                          <ShortButton
                            kind="cancel"
                            onClick={() => {
                              setDepositingIndex(-1)
                            }}
                            inverse
                          >
                            Cancel
                          </ShortButton>
                          <ApproveButton
                            size="small"
                            amount={toScaled(fields.depositAmount || 0, 6)}
                            spender={addresses.Vault}
                          >
                            <ShortButton
                              kind="primary"
                              onClick={() => {
                                onDeposit(short.accountId, short.expiryId)
                              }}
                            >
                              Deposit
                            </ShortButton>
                          </ApproveButton>
                        </ButtonContainer>
                      </LargeCell>
                    ) : (
                      <LargeCell>
                        {short.state === PositionState.Live ? (
                          <ShortButton
                            size="small"
                            kind="primary"
                            onClick={() => {
                              setDepositingIndex(i)
                            }}
                            inverse
                          >
                            Deposit
                          </ShortButton>
                        ) : (
                          <div />
                        )}
                        {short.state === PositionState.Finalized ? (
                          <ShortButton
                            size="small"
                            kind="primary"
                            onClick={() => {
                              onSettle(short.accountId, short.expiryId)
                            }}
                            inverse={short.isSettled}
                            disabled={short.isSettled}
                          >
                            {short.isSettled ? 'Already Settled' : 'Settle'}
                          </ShortButton>
                        ) : short.state === PositionState.Pending ? (
                          <div>Pending</div>
                        ) : short.longSize.gte(short.amount) ? (
                          <ShortButton
                            size="small"
                            kind="primary"
                            disabled={short.amount.eq(0) || short.premium.eq(0)}
                            onClick={() => {
                              onCloseVault(
                                short.accountId,
                                short.seriesId,
                                short.amount
                              )
                            }}
                          >
                            Close position
                          </ShortButton>
                        ) : (
                          <ApproveButton
                            size="small"
                            amount={short.maxPremium}
                            spender={addresses.AMM}
                          >
                            <ShortButton
                              size="small"
                              kind="primary"
                              inverse={short.premium.eq(0)}
                              onClick={() => {
                                onCloseToBuy(
                                  short.seriesId,
                                  short.amount,
                                  short.maxPremium
                                )
                              }}
                            >
                              Close to buy
                            </ShortButton>
                          </ApproveButton>
                        )}
                      </LargeCell>
                    )}
                  </ListItem>
                ))
            ) : (
              <div />
            )}
          </List>
        </SectionBody>
      </Section>
      <ReactTooltip effect="solid" className="tooltip-mw-120" />
    </Container>
  )
}

const Container = styled.div`
  padding: 30px;
`

const Head = styled.div`
  display: flex;
`

const SectionBody = styled.div`
  padding: ${SPACE.X_LARGE};
`

const PrimaryLabel = styled.div`
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${FONT_SIZE.SMALL};
`

const List = styled.div``

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderItem = styled(PrimaryLabel)`
  width: 80px;
`

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACE.MEDIUM} 0;
  border-bottom: solid 1px ${({ theme }) => theme.palette.surface};
`

const ListCell = styled.div`
  width: 80px;
  font-size: ${FONT_SIZE.SMALL};
  display: flex;
`

const SmallCell = styled(ListCell)`
  width: 40px;
`

const LargeCell = styled(ListCell) <{ selected?: boolean }>`
  width: 210px;
  ${({ selected, theme }) =>
    selected
      ? `
    background-color: ${theme.palette.surface2};
    border-radius: ${RADIUS.SMALL};
    padding: ${SPACE.SMALL};
    padding-bottom: ${SPACE.MEDIUM};
    flex-direction: column;
    `
      : ''}
`

const SelectedCellTitle = styled(HeaderItem)`
  width: 100%;
  padding: ${SPACE.MEDIUM} ${SPACE.SMALL};
  margin-bottom: ${SPACE.MEDIUM};
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const ClaimButton = styled(Button)`
  width: 80px;
`

const ShortButton = styled(Button)`
  width: 80px;
  font-size: ${FONT_SIZE.X_SMALL};
`

const SelectedMContainer = styled.div`
  padding: ${SPACE.SMALL};
  margin-bottom: ${SPACE.MEDIUM};
  display: flex;
  justify-content: space-between;
`

const SelectedValueContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: ${SPACE.MEDIUM} 0;
`

const SelectedValue = styled.span`
  margin-left: ${SPACE.MEDIUM};
  font-weight: 500;
`

const HeadItemContainer = styled.div`
  margin-right: ${SPACE.SMALL};
  display: flex;
  align-items: center;
`

const HeadValue = styled(FormValue)`
  min-width: 60px;
  margin-left: ${SPACE.SMALL};
  padding: 0 12px;
`

const Icon = styled.img`
  margin-right: ${SPACE.SMALL};
`

const HelpIcon = styled.span`
  margin-left: ${SPACE.SMALL};
  cursor: default;
`

export default AccountInfoView
