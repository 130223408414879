import React from 'react'
import styled from 'styled-components'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid
} from 'recharts'

import { Dropdown, Input, Label, FormValue } from '../components/common/Form'
import { Button } from '../components/common/Button'
import { Section, SectionTitle } from '../components/common/Section'
import { FONT_SIZE, SPACE } from '../constants'
import {
  useDepositAmount,
  useLiquidityChartData,
  useLiquidityList,
  useDepositMutation,
  useWithdrawMutation,
  useLockupState
} from '../hooks/query/amm'
import { BigNumber } from '@ethersproject/bignumber'
import { toScaled, toUnscaled } from '../utils/bn'
import { useForm } from 'react-hook-form'
import { useTokenAddresses } from '../hooks/addresses'
import { useERC20BalanceQuery } from '../hooks/query/balance'
import { useAllowanceQuery, useApproveMutation } from '../hooks/query/allowance'
import ConnectButton from '../components/common/ConnectButton'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useCurrentEthPrice } from '../hooks/price'
import dayjs from 'dayjs'
import DepositIcon from '../assets/deposit_icon_black.svg'
import DepositListIcon from '../assets/deposit_list_icon_black.svg'
import PoolIcon from '../assets/pool_icon_yellow.svg'
import mixpanel from '../mixpanel'

const LOWER_TICK = 8
const UPPER_TICK = 15

type FormData = {
  mintAmount: number
  lower: number
  upper: number
}

const PoolView = () => {
  const { active } = useWeb3React<ethers.providers.Web3Provider>()

  const addresses = useTokenAddresses()

  const { register, handleSubmit, watch } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      mintAmount: 0,
      lower: 10,
      upper: 12
    }
  })

  const fields = watch()

  const balanceQuery = useERC20BalanceQuery(addresses.PayingToken)
  const allowanceQuery = useAllowanceQuery(addresses.AMM, addresses.PayingToken)

  const lockupState = useLockupState()

  const chartData = useLiquidityChartData(LOWER_TICK, UPPER_TICK)
  const liquidityList = useLiquidityList(LOWER_TICK, UPPER_TICK)
  const depositAmount = useDepositAmount(
    toScaled(fields.mintAmount || 0, 6),
    fields.lower,
    fields.upper
  )

  const deposit = useDepositMutation()
  const withdraw = useWithdrawMutation()
  const approve = useApproveMutation()
  const priceQuery = useCurrentEthPrice()

  const onApprove = async () => {
    if (!depositAmount.isSuccess) {
      return
    }

    const maxDepositAmount = depositAmount.data

    await approve.mutateAsync({
      amount: maxDepositAmount,
      address: addresses.PayingToken,
      spender: addresses.AMM
    })

    mixpanel.track('pool approve', {
      amount: maxDepositAmount.toString(),
      address: addresses.PayingToken,
      spender: addresses.AMM
    })
  }

  const onSubmit = handleSubmit(async (data: FormData) => {
    if (!depositAmount.isSuccess) {
      return
    }

    if (!allowanceQuery.isSuccess) {
      return
    }

    if (allowanceQuery.data.lt(depositAmount.data)) {
      return
    }

    await deposit.mutateAsync({
      mint: toScaled(data.mintAmount, 6),
      maxDeposit: depositAmount.data,
      lower: data.lower,
      upper: data.upper
    })

    mixpanel.track('pool deposit', {
      mint: toScaled(data.mintAmount, 6).toString(),
      maxDeposit: depositAmount.data.toString(),
      lower: data.lower,
      upper: data.upper
    })
  })

  const onWithdraw = async (
    rangeId: number,
    burn: BigNumber,
    minWithdrawal: BigNumber
  ) => {
    await withdraw.mutateAsync({
      rangeId,
      burn,
      minWithdrawal,
      useReservation: false
    })
    mixpanel.track('pool withdraw', {
      rangeId,
      burn: burn.toString(),
      minWithdrawal: minWithdrawal.toString(),
      useReservation: false
    })
  }

  return (
    <Container>
      <Head>
        <HeadItemContainer>
          <Label>Underlying</Label>
          <HeadValue>ETH</HeadValue>
        </HeadItemContainer>
        <HeadItemContainer>
          <Label>ETH Price</Label>
          <HeadValue>
            ${priceQuery.data ? Math.floor(priceQuery.data) : '...'}
          </HeadValue>
        </HeadItemContainer>
      </Head>
      <BodyContainer>
        <Body>
          <Section>
            <SectionTitle>
              <TitleWrapper>
                <Icon src={DepositIcon} />
                Deposit
              </TitleWrapper>
            </SectionTitle>
            <SectionBody>
              <form onSubmit={onSubmit}>
                <SectionFormControl>
                  <SectionLabel>Range</SectionLabel>
                  <Dropdown
                    {...register('lower', {
                      required: true,
                      valueAsNumber: true
                    })}
                  >
                    <option value="7">70%</option>
                    <option value="8">80%</option>
                    <option value="9">90%</option>
                    <option value="10">100%</option>
                    <option value="11">110%</option>
                    <option value="12">120%</option>
                    <option value="13">130%</option>
                    <option value="14">140%</option>
                  </Dropdown>
                  <Dropdown
                    {...register('upper', {
                      required: true,
                      valueAsNumber: true
                    })}
                  >
                    <option value="9">90%</option>
                    <option value="10">100%</option>
                    <option value="11">110%</option>
                    <option value="12">120%</option>
                    <option value="13">130%</option>
                    <option value="14">140%</option>
                    <option value="15">150%</option>
                    <option value="16">160%</option>
                    <option value="17">170%</option>
                    <option value="18">180%</option>
                    <option value="19">190%</option>
                    <option value="20">200%</option>
                  </Dropdown>
                </SectionFormControl>
                <SectionFormControl>
                  <SectionLabel>Amount</SectionLabel>
                  <Input
                    {...register('mintAmount', {
                      required: true,
                      valueAsNumber: true
                    })}
                  />
                </SectionFormControl>
                <SectionFormControl>
                  <SectionLabel>Deposit Amount</SectionLabel>
                  {depositAmount.isSuccess
                    ? toUnscaled(depositAmount.data, 6).toLocaleString()
                    : 0}{' '}
                  USDC
                </SectionFormControl>
                <ButtonControl>
                  {active ? (
                    balanceQuery.isSuccess &&
                      allowanceQuery.isSuccess &&
                      depositAmount.isSuccess ? (
                      balanceQuery.data.gte(depositAmount.data) ? (
                        allowanceQuery.data.gte(depositAmount.data) ? (
                          <FullButton kind="primary" type="submit">
                            Deposit
                          </FullButton>
                        ) : (
                          <FullButton kind="primary" onClick={onApprove}>
                            Approve
                          </FullButton>
                        )
                      ) : (
                        <FullButton kind="cancel" disabled>
                          Insufficient balance
                        </FullButton>
                      )
                    ) : (
                      <FullButton type="submit" kind="primary" disabled>
                        Deposit
                      </FullButton>
                    )
                  ) : (
                    <ConnectButton>Connect Wallet to deposit</ConnectButton>
                  )}
                </ButtonControl>
                <Message>Your liquidity will be locked for 2 weeks from last deposit.</Message>
              </form>
            </SectionBody>
          </Section>
          <Section>
            <SectionTitle>
              <TitleWrapper>
                <Icon src={DepositListIcon} />
                Deposit List
              </TitleWrapper>
            </SectionTitle>
            <SectionBody>
              <DepositListHeader>
                <HeaderItem>Range</HeaderItem>
                <HeaderItem>Balance</HeaderItem>
                <HeaderItem></HeaderItem>
              </DepositListHeader>
              {liquidityList.isSuccess ? (
                liquidityList.data.map((liquidityItem, i) => (
                  <DepositListItem key={i}>
                    <ListCell>
                      {liquidityItem.range.tickStart * 10}%-
                      {liquidityItem.range.tickEnd * 10}%
                    </ListCell>
                    <ListCell>
                      {toUnscaled(
                        liquidityItem.withdrawableAmount,
                        6
                      ).toLocaleString()}{' '}
                      USDC
                    </ListCell>
                    <ListCell>
                      {lockupState.isSuccess ? (
                        !lockupState.data.isLockedUp ? (
                          <Button
                            kind="primary"
                            size="small"
                            onClick={() => {
                              onWithdraw(
                                liquidityItem.rangeId,
                                liquidityItem.tokenAmount,
                                liquidityItem.withdrawableAmount
                              )
                            }}
                            inverse
                          >
                            withdraw
                          </Button>
                        ) : (
                          <p>
                            withdrawal locked up until{' '}
                            {dayjs
                              .unix(lockupState.data.until)
                              .format('MMM DD')}
                          </p>
                        )
                      ) : (
                        <div />
                      )}
                    </ListCell>
                  </DepositListItem>
                ))
              ) : (
                <div />
              )}
            </SectionBody>
          </Section>
        </Body>
        <ChartContainer>
          <ChartTitle>
            <TitleWrapper>
              <Icon src={PoolIcon} />
              Liquidity Chart
            </TitleWrapper>
          </ChartTitle>
          {chartData.isSuccess ? (
            <ResponsiveContainer width="100%" height={340}>
              <BarChart data={chartData.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="iv" />
                <YAxis dataKey="liquidity" />
                <Bar
                  dataKey="liquidity"
                  fill="#2F42E7"
                  radius={[50, 50, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div />
          )}
        </ChartContainer>
      </BodyContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 30px;
`

const Head = styled.div`
  display: flex;
`

const BodyContainer = styled.div`
  display: flex;
  @media (max-width: 1060px) {
    flex-direction: column;
  }
`

const Body = styled.div`
  width: 420px;
  @media (max-width: 1060px) {
    width: 100%;
  }
`

const ChartContainer = styled.div`
  margin-left: ${SPACE.LARGE};
  width: calc(100% - 400px);

  @media (max-width: 1060px) {
    margin-top: ${SPACE.X_LARGE};
    width: calc(100% - 20px);
  }
`

const SectionBody = styled.div`
  padding: ${SPACE.X_LARGE};
`

const SectionFormControl = styled.div`
  margin-bottom: ${SPACE.LARGE};
`

const SectionLabel = styled.span`
  color: ${({ theme }) => theme.palette.primary};
  display: inline-block;
  width: 60px;
  font-size: ${FONT_SIZE.SMALL};
`

const ButtonControl = styled.div`
  display: flex;
  justify-content: space-between;
`

const FullButton = styled(Button)`
  flex: 1;
  &:first-child {
    margin-right: 10px;
  }
`

const DepositListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderItem = styled.div`
  color: ${({ theme }) => theme.palette.primary};
  width: 80px;
  font-size: ${FONT_SIZE.SMALL};
`

const DepositListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACE.MEDIUM} 0;
  border-bottom: solid 1px ${({ theme }) => theme.palette.surface};
`

const ListCell = styled.div`
  width: 80px;
  font-size: ${FONT_SIZE.SMALL};
`

const ChartTitle = styled.h3`
  color: ${({ theme }) => theme.palette.primary};
  margin-bottom: ${SPACE.XX_LARGE};
`

const HeadItemContainer = styled.div`
  margin-right: ${SPACE.SMALL};
  display: flex;
  align-items: center;
`

const HeadValue = styled(FormValue)`
  min-width: 60px;
  margin-left: ${SPACE.SMALL};
  padding: 0 12px;
`

const Icon = styled.img`
  margin-right: ${SPACE.SMALL};
`

const TitleWrapper = styled.p`
  display: flex;
  align-items: center;
`

const Message = styled.p`
  margin-top: ${SPACE.SMALL};
  font-size: ${FONT_SIZE.SMALL};
`

export default PoolView
