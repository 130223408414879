import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'wouter'
import localforage from 'localforage'
import { Button } from '../components/common/Button'
import { Switch } from '../components/common/Switch'
import Logo from '../assets/Logo.png'
import { FONT_SIZE, RADIUS, SPACE } from '../constants'
import LoadingIndicator from '../components/common/LoadingIndicator'
import mixpanel from '../mixpanel'

const AgreementView = () => {
  const setLocation = useLocation()[1]
  const [loaded, setLoaded] = useState(false)
  const [analytics, setAnalytics] = useState(false)
  const [residence, setResidence] = useState(false)

  useEffect(() => {
    ;(async () => {
      // check residence once. if value is set to true, proceed
      const value = await localforage.getItem('residence')
      if (value) {
        setLocation('/trade/eth-call')
      }
      setLoaded(true)
    })()
    // eslint-disable-next-line
  }, [])

  const onAccept = async () => {
    // setAccept data in localstorage
    await localforage.setItem('analytics', analytics)
    await localforage.setItem('residence', residence)
    if (!analytics) {
      mixpanel.opt_out_tracking()
    }

    setLocation('/trade/eth-call')
  }

  if (!loaded) return <LoadingIndicator />

  return (
    <Container>
      <Body>
        <Head>
          <img src={Logo} alt="Logo" width="148px" />
        </Head>
        <Title>Welcome To Demo Release</Title>
        <Text>
          Thank you for trying Predy. Here you can trade options and provide
          liquidity up to the published TVL limit. For this first service, we
          have chosen the upper limit to provide a better and safer measurement
          for the next series of options. The current live version of the
          contract has been audited, but we would like to make sure it is safe
          little by little.
        </Text>
        <SwitchContainer>
          <SwitchItem>
            <div>
              <Switch onChange={v => setAnalytics(v)} />
            </div>
            <SwitchDescription>
              (Optional) You agree to share anonymous analytics and crashalytics
            </SwitchDescription>
          </SwitchItem>
          <SwitchItem>
            <div>
              <Switch onChange={v => setResidence(v)} />
            </div>
            <SwitchDescription>
              (Mandatory) You are not a person or company who is a resident of,
              or is located, incorporated or has a registered agent in,
              theUnited States or any prohibited locations where you use of the
              service would be illegal or otherwise violate any applicable law
            </SwitchDescription>
          </SwitchItem>
        </SwitchContainer>
        <ButtonContainer>
          <AcceptButton
            onClick={onAccept}
            disabled={!residence}
            kind="primary"
            size="large"
          >
            Accept & Join
          </AcceptButton>
        </ButtonContainer>
      </Body>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Body = styled.div`
  width: 480px;
  background-color: ${({ theme }) => theme.palette.surface2};
  border-radius: ${RADIUS.LARGE};
  padding: 50px;
  margin-top: 60px;
`
const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${SPACE.X_LARGE};
  border-bottom: solid 1px ${({ theme }) => theme.palette.onSurface};
`

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.onSurface};
  font-weight: 500;
  font-size: ${FONT_SIZE.LARGE};
  margin-top: 40px;
`

const Text = styled.p`
  color: ${({ theme }) => theme.palette.onSurface};
  font-size: ${FONT_SIZE.SMALL};
  margin-top: ${SPACE.MEDIUM};
  line-height: 15px;
`

const ButtonContainer = styled.div`
  margin-top: ${SPACE.XX_LARGE};
  width: 100%;
  display: flex;
  justify-content: center;
`

const AcceptButton = styled(Button)<{ disabled?: boolean }>`
  width: 180px;
  font-size: ${FONT_SIZE.MEDIUM};

  ${({ disabled, theme }) =>
    disabled
      ? `background-color: ${theme.palette.disabled}; cursor: unset;`
      : ''}
`

const SwitchContainer = styled.div`
  margin: ${SPACE.XX_LARGE} 0;
`

const SwitchItem = styled.div`
  display: flex;
  align-items: center;
  margin: ${SPACE.LARGE} 0;
`

const SwitchDescription = styled.p`
  margin-left: ${SPACE.SMALL};
  color: ${({ theme }) => theme.palette.onSurface};
  font-size: ${FONT_SIZE.SMALL};
`

export default AgreementView
