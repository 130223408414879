import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers, BigNumber } from 'ethers'
import { useAddresses } from '../addresses'
import { Liquidation__factory } from '../../typechain/flashloan/factories/Liquidation__factory'

type LiquidationParams = {
  accountId: BigNumber
  seriesId: number
  liqAmount: BigNumber
  maxFee: BigNumber
}

export function useLiquidateMutation() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()

  return useMutation(async (params: LiquidationParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')

    const contract = Liquidation__factory.connect(
      addresses.Liquidation,
      library.getSigner()
    )

    return await contract.liquidate(params.accountId, params.seriesId, params.liqAmount, params.maxFee)
  })
}