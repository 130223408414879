import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled, { useTheme } from 'styled-components'
import { useRoute, useLocation } from 'wouter'
import ReactModal from 'react-modal'
import { BigNumber } from 'ethers'
import { Button } from '../components/common/Button'
import { Dropdown, FormValue, Input, Label } from '../components/common/Form'
import { Section, SectionTitle } from '../components/common/Section'
import { FONT_SIZE, SPACE } from '../constants'
import {
  useDealData,
  useBuyOptionMutation,
  useMakeShortPositionMutation
} from '../hooks/query/amm'
import { toScaled, toUnscaled } from '../utils/bn'
import { useTokenAddresses } from '../hooks/addresses'
import { useERC20BalanceQuery } from '../hooks/query/balance'
import { useAllowanceQuery, useApproveMutation } from '../hooks/query/allowance'
import { getCallPut } from '../utils/series'
import dayjs from 'dayjs'
import ResultIcon from '../assets/results_icon_black.svg'
import SuccessIcon from '../assets/Success.svg'
import mixpanel from '../mixpanel'

type FormData = {
  size: number
  isPut: 'put' | 'call'
  isSelling: 'sell' | 'buy'
  imRatio: number
}

const DealView = () => {
  // get series from params seriesId
  const params = useRoute('/deal/:option/:seriesId')[1]

  const setLocation = useLocation()[1]
  const [modalOpen, setModalOpen] = useState(false)

  const seriesId = params ? Number(params.seriesId) : null
  const callPut = params ? getCallPut(params.option) : 'call'
  const isSelling = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  ).sell

  const addresses = useTokenAddresses()
  const theme = useTheme()

  const { register, watch } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      size: 1,
      isPut: 'call',
      isSelling: isSelling === 'true' ? 'sell' : 'buy',
      imRatio: 100
    }
  })

  const fields = watch()

  const balanceQuery = useERC20BalanceQuery(addresses.PayingToken)
  const allowanceVaultQuery = useAllowanceQuery(
    addresses.Vault,
    addresses.PayingToken
  )
  const allowanceAMMQuery = useAllowanceQuery(
    addresses.AMM,
    addresses.PayingToken
  )

  const approve = useApproveMutation()
  const buyOption = useBuyOptionMutation()
  const makeShortOption = useMakeShortPositionMutation()

  const dealData = useDealData(
    seriesId,
    toScaled(fields.size || 1, 8),
    fields.isSelling === 'sell',
    fields.imRatio
  )

  const sizeTooLarge = dealData.isError

  const onApprove = async () => {
    if (!dealData.isSuccess) {
      return
    }

    const amount =
      fields.isSelling === 'sell'
        ? dealData.data.maxApproveCollateral
        : dealData.data.maxPremium
    const spender =
      fields.isSelling === 'sell' ? addresses.Vault : addresses.AMM

    await approve.mutateAsync({
      amount,
      address: addresses.PayingToken,
      spender
    })

    mixpanel.track('deal approve', {
      amount: amount.toString(),
      address: addresses.PayingToken,
      spender
    })
  }

  const onBuy = async (
    seriesId: number,
    amount: BigNumber,
    maxFee: BigNumber
  ) => {
    try {
      await buyOption.mutateAsync({
        seriesId,
        amount,
        maxFee
      })

      setModalOpen(true)
      mixpanel.track('deal buy', {
        seriesId,
        amount: amount.toString(),
        maxFee: maxFee.toString()
      })
    } catch (e) {
      // TODO: buyOption fails
    }
  }

  const onSell = async (
    seriesId: number,
    amount: BigNumber,
    cRatio: BigNumber,
    minFee: BigNumber
  ) => {
    try {
      await makeShortOption.mutateAsync({
        accountId: 0,
        seriesId,
        amount,
        cRatio,
        minFee
      })

      setModalOpen(true)

      mixpanel.track('deal sell', {
        accountId: 0,
        seriesId,
        amount: amount.toString(),
        cRatio: cRatio.toString(),
        minFee: minFee.toString()
      })
    } catch (e) {
      // TODO: sell option fails
    }
  }

  const changeCallPut = (value: string) => {
    // if not changed, return
    if (value === callPut) return

    const nextId =
      value === 'call'
        ? Number((params as any).seriesId) - 1
        : Number((params as any).seriesId) + 1
    setLocation(`/deal/eth-${value}/${nextId}`)
  }

  return (
    <Container>
      <Head>
        <HeadItemContainer>
          <Label>Underlying</Label>
          <HeadValue>ETH</HeadValue>
        </HeadItemContainer>
        <HeadItemContainer>
          <Label>Strike</Label>
          <HeadValue>
            {dealData.data
              ? toUnscaled(dealData.data.strike, 8).toLocaleString()
              : ''}
          </HeadValue>
        </HeadItemContainer>
        <HeadItemContainer>
          <Label>Strike(%)</Label>
          <HeadValue>
            {dealData.isSuccess ? dealData.data.strikePercentage : 0}
          </HeadValue>
        </HeadItemContainer>
        <HeadItemContainer>
          <Label>Vol</Label>
          <HeadValue>
            {dealData.data ? toUnscaled(dealData.data.iv, 6, 1) + '%' : '%'}
          </HeadValue>
        </HeadItemContainer>
      </Head>
      <Section>
        <SectionTitle>
          <Icon src={ResultIcon} />
          Results
        </SectionTitle>
        <ResultList>
          <ListItem>
            <ResultLabel>Price (/size)</ResultLabel>
            <ResultValue>
              {dealData.isSuccess
                ? (
                  toUnscaled(dealData.data.premium, 6) / (fields.size || 1)
                ).toLocaleString()
                : 0}{' '}
              USDC
            </ResultValue>
          </ListItem>
          <ListItem>
            <ResultLabel>Price (Total)</ResultLabel>
            <ResultValue>
              {dealData.isSuccess
                ? toUnscaled(dealData.data.premium, 6).toLocaleString()
                : 0}{' '}
              USDC
            </ResultValue>
          </ListItem>
          <ListItem>
            <ResultLabel>Currency</ResultLabel>
            <ResultValue>USDC</ResultValue>
          </ListItem>
          <ListItem>
            <ResultLabel>Delta</ResultLabel>
            <ResultValue>
              {dealData.isSuccess ? toUnscaled(dealData.data.delta, 8, 3) : 0}
            </ResultValue>
          </ListItem>
          {fields.isSelling === 'sell' ? (
            <>
              <ResultSubTitle>
                Margin Info
                <Info>
                  ℹ️ You need to add margin more than{' '}
                  {dealData.isSuccess
                    ? toUnscaled(dealData.data.collateral, 6).toLocaleString()
                    : '-'}{' '}
                  USDC to sell this position
                </Info>
              </ResultSubTitle>
              <ListItem>
                <ResultLabel>
                  Margin Bar (Initial Margin/Collateral)
                </ResultLabel>
                <ResultValue>
                  <Dropdown
                    style={{ width: '90px' }}
                    {...register('imRatio', {
                      required: true,
                      valueAsNumber: true
                    })}
                  >
                    <option value={100}>100%</option>
                    <option value={90}>90%</option>
                    <option value={80}>80%</option>
                    <option value={70}>70%</option>
                    <option value={60}>60%</option>
                    <option value={50}>50%</option>
                    <option value={40}>40%</option>
                    <option value={30}>30%</option>
                  </Dropdown>
                </ResultValue>
              </ListItem>
              <ListItem>
                <ResultLabel>Margin (To Be Added)</ResultLabel>
                <ResultValue>
                  {dealData.isSuccess
                    ? toUnscaled(dealData.data.collateral, 6).toLocaleString()
                    : 0}{' '}
                  USDC
                </ResultValue>
              </ListItem>
            </>
          ) : (
            <></>
          )}
        </ResultList>
      </Section>
      <ButtonSection>
        <FormRow>
          <FormItem>
            <FormLabel>Call/Put</FormLabel>
            <Dropdown
              onChange={e => changeCallPut(e.target.value)}
              style={{ width: '90px' }}
              value={callPut}
            >
              <option value="call">Call</option>
              <option value="put">Put</option>
            </Dropdown>
          </FormItem>
          <FormItem>
            <FormLabel>Direction</FormLabel>
            <Dropdown
              style={{ width: '90px' }}
              {...register('isSelling', { required: true })}
            >
              <option value="sell">Sell</option>
              <option value="buy">Buy</option>
            </Dropdown>
          </FormItem>
        </FormRow>
        <FormRow>
          <FormItem>
            <FormLabel>Size</FormLabel>
            <Input
              style={{ width: '90px' }}
              {...register('size', { required: true, valueAsNumber: true })}
            />
          </FormItem>
          <FormItem>
            <FormLabel>Expiration</FormLabel>
            <Dropdown style={{ width: '90px' }}>
              <option>
                {dealData.isSuccess
                  ? dayjs.unix(dealData.data.expiry.toNumber()).format('MMM DD')
                  : ''}
              </option>
            </Dropdown>
          </FormItem>
        </FormRow>
        <ErrorSection>
          {sizeTooLarge && (
            <ErrorMessage>
              Size too large. Liquidity is not large enough for the deal.
            </ErrorMessage>
          )}
        </ErrorSection>
        <ButtonContainer>
          <ResultButton
            kind="cancel"
            inverse
            onClick={() => {
              setLocation(`/trade/${params?.option}`)
            }}
          >
            Cancel
          </ResultButton>
          {fields.isSelling === 'sell' ? (
            balanceQuery.isSuccess &&
              dealData.isSuccess &&
              allowanceVaultQuery.isSuccess &&
              balanceQuery.data.gte(dealData.data.maxApproveCollateral) ? (
              allowanceVaultQuery.data.gte(dealData.data.maxApproveCollateral) ? (
                <ResultButton
                  kind="primary"
                  onClick={() => {
                    onSell(
                      dealData.data.seriesId.toNumber(),
                      toScaled(fields.size || 1, 8),
                      toScaled(fields.imRatio, 4),
                      dealData.data.minPremium
                    )
                  }}
                >
                  Sell
                </ResultButton>
              ) : (
                <ResultButton
                  kind="primary"
                  onClick={() => {
                    onApprove()
                  }}
                >
                  Approve
                </ResultButton>
              )
            ) : (
              <ResultButton kind="primary">Insufficient Balance</ResultButton>
            )
          ) : balanceQuery.isSuccess &&
            dealData.isSuccess &&
            allowanceAMMQuery.isSuccess &&
            balanceQuery.data.gte(dealData.data.maxPremium) ? (
            allowanceAMMQuery.data.gte(dealData.data.maxPremium) ? (
              <ResultButton
                kind="primary"
                onClick={() => {
                  onBuy(
                    dealData.data.seriesId.toNumber(),
                    toScaled(fields.size || 1, 8),
                    dealData.data.maxPremium
                  )
                }}
              >
                Buy
              </ResultButton>
            ) : (
              <ResultButton
                kind="primary"
                onClick={() => {
                  onApprove()
                }}
              >
                Approve
              </ResultButton>
            )
          ) : sizeTooLarge ? (
            <ResultButton kind="primary" disabled>
              Size too large
            </ResultButton>
          ) : (
            <ResultButton kind="primary">Insufficient Balance</ResultButton>
          )}
        </ButtonContainer>
      </ButtonSection>
      <TradeStepMessage>
        Actions to complete with 4 steps<br />
        Approve USDC -&gt; confirm (metamask) -&gt; Buy / Sell (Approve button will change) -&gt; confirm (metamask)
      </TradeStepMessage>
      <ReactModal
        isOpen={modalOpen}
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.15)'
          },
          content: {
            width: '320px',
            height: '320px',
            border: 'none',
            background: theme.palette.background,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '12px',
            outline: 'none',
            padding: '20px',
            inset: 'auto'
          }
        }}
      >
        <ModalContent>
          <Icon
            src={SuccessIcon}
            style={{ width: '120px', height: '120px', marginTop: '20px' }}
          />
          <div>
            <ModalTitle>Transaction successfully sent</ModalTitle>
            <ModalNote>Wait for the tx to be included in a block</ModalNote>
          </div>

          <Button kind="primary" onClick={() => setModalOpen(false)}>
            Close to continue
          </Button>
        </ModalContent>
      </ReactModal>
    </Container>
  )
}

const Container = styled.div`
  padding: 30px;
`

const Head = styled.div`
  display: flex;
`

const HeadItemContainer = styled.div`
  margin-right: ${SPACE.SMALL};
  display: flex;
  align-items: center;
`

const ResultList = styled.div`
  padding: ${SPACE.LARGE};
`

const ResultLabel = styled.span`
  color: ${({ theme }) => theme.palette.primary};
  overflow-x: hidden;
  max-width: 40em;
  background-color: ${({ theme }) => theme.palette.surface3};

  &:after {
    color: white;
    content: '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . ';
    float: left;
    width: 0;
    white-space: nowrap;
    vertical-align: center;
  }
`

const ResultValue = styled.span`
  float: right;
  background-color: ${({ theme }) => theme.palette.surface3};
  padding-left: ${SPACE.MEDIUM};
`

const ListItem = styled.div`
  margin-bottom: ${SPACE.XX_LARGE};
  overflow-x: hidden;
`

const ResultSubTitle = styled.h4`
  font-size: ${FONT_SIZE.LARGE};
  margin-bottom: ${SPACE.LARGE};
  display: flex;
  align-items: center;
`

const Info = styled.span`
  font-size: ${FONT_SIZE.X_SMALL};
  font-weight: normal;
  margin-left: ${SPACE.SMALL};
`

const ButtonSection = styled.div`
  margin-top: ${SPACE.XX_LARGE};
`

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACE.MEDIUM};
`

const FormLabel = styled(Label)`
  width: 80px;
  display: inline-block;
`

const FormItem = styled.div`
  width: 50%;
`

const ButtonContainer = styled.div`
  margin-top: ${SPACE.LARGE};
  display: flex;
  justify-content: space-between;
`

const ResultButton = styled(Button)`
  flex: 1;
  &:first-child {
    margin-right: ${SPACE.MEDIUM};
  }
`

const HeadValue = styled(FormValue)`
  min-width: 60px;
  margin-left: ${SPACE.SMALL};
  padding: 0 12px;
`

const Icon = styled.img`
  margin-right: ${SPACE.SMALL};
`

const ErrorSection = styled.div`
  min-height: 20px;
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.error};
  font-size: ${FONT_SIZE.SMALL};
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const ModalTitle = styled.p`
  color: ${({ theme }) => theme.palette.onSurface};
  font-size: ${FONT_SIZE.LARGE};
  margin-bottom: ${SPACE.LARGE};
`

const ModalNote = styled.p`
  color: ${({ theme }) => theme.palette.onSurface};
  font-size: ${FONT_SIZE.MEDIUM};
`

const TradeStepMessage = styled.div`
  color: ${({ theme }) => theme.palette.onSecondary};
  margin:  ${SPACE.LARGE} auto;
`

export default DealView
