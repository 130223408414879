import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useLocation, useRoute } from 'wouter'
import { Label, FormValue } from '../../components/common/Form'
import LoadingIndicator from '../../components/common/LoadingIndicator'
import Toggle from '../../components/common/Toggle'
import { RADIUS, SPACE } from '../../constants'
import { useCurrentEthPrice } from '../../hooks/price'
import { ValueType, IVType, useTradeBoardData } from '../../hooks/query/amm'
import useTabStore, { Tab } from '../../store/tab'
import useTermStore from '../../store/term'
import { getATMStrike } from '../../utils/series'
import mixpanel from '../../mixpanel'
import { toScaled, toUnscaled } from '../../utils/bn'

type FormData = {
  valueType: ValueType
  ivType: IVType
}

function isValidOption(option: string | undefined) {
  return option && (option === 'eth-call' || option === 'eth-put')
}

const TableView = () => {
  const params = useRoute('/trade/:option')[1]

  const setLocation = useLocation()[1]

  const { watch, setValue } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      valueType: ValueType.IV,
      ivType: IVType.Buy
    }
  })

  const fields = watch()

  const ethPrice = useCurrentEthPrice()
  const tradeData = useTradeBoardData(
    fields.valueType,
    fields.ivType,
    params?.option === 'eth-put'
  )
  const [hilightedStrike, setHighlightedStrike] = useState<number | undefined>(
    undefined
  )
  const [hilightedExpiry, setHighlightedExpiry] = useState<number | undefined>(
    undefined
  )

  const { setTab } = useTabStore()
  const { strike, setStrike, setExpiry } = useTermStore()

  if (!isValidOption(params?.option)) {
    return <div>Invalid option</div>
  }

  if (
    strike === undefined &&
    ethPrice.isSuccess &&
    tradeData.isSuccess &&
    tradeData.data.strikes.length > 0
  ) {
    setStrike(getATMStrike(ethPrice.data, tradeData.data.strikes))
  }

  return (
    <Container>
      <FilterContainer>
        <ToggleContainer>
          <Toggle
            options={[
              { label: 'Implied Volatility', value: ValueType.IV },
              { label: 'Premium', value: ValueType.Premium }
            ]}
            value={fields.valueType}
            onChange={(value: ValueType) => {
              setValue('valueType', value)
              mixpanel.track('toggle table value type', {
                value
              })
            }}
          />
        </ToggleContainer>
        <ToggleContainer>
          <Toggle
            options={[
              { label: 'Buy', value: IVType.Buy },
              { label: 'Sell', value: IVType.Sell }
            ]}
            value={fields.ivType}
            onChange={(value: IVType) => {
              setValue('ivType', value)
              mixpanel.track('toggle table iv type', {
                value
              })
            }}
          />
        </ToggleContainer>
        <HeadItemContainer>
          <Label>ETH Price</Label>
          <HeadValue>
            ${ethPrice.data ? toUnscaled(toScaled(ethPrice.data, 8), 8, 2) : '...'}
          </HeadValue>
        </HeadItemContainer>
      </FilterContainer>
      {!tradeData.isLoading && tradeData.data ? (
        <TableContainer>
          <Table>
            <tbody>
              <Row>
                <Cell>Expiration</Cell>
                <Cell>Market</Cell>
                {tradeData.data.strikes.map(strike => (
                  <ClickableCell
                    key={strike}
                    onMouseEnter={() => {
                      setHighlightedStrike(strike)
                    }}
                    onMouseLeave={() => {
                      setHighlightedStrike(undefined)
                    }}
                    onClick={() => {
                      setStrike(strike)
                      setTab(Tab.Term)
                    }}
                  >
                    ${strike.toLocaleString()}
                  </ClickableCell>
                ))}
              </Row>
              {tradeData.data.data.map(row => (
                <Row key={`${row.expiration}${row.market}`}>
                  <ClickableCell
                    onMouseEnter={() => {
                      setHighlightedExpiry(row.expiryInSeconds)
                    }}
                    onMouseLeave={() => {
                      setHighlightedExpiry(undefined)
                    }}
                    onClick={() => {
                      setExpiry(row.expiryInSeconds)
                      setTab(Tab.Skew)
                    }}
                  >
                    {row.expiration}
                  </ClickableCell>
                  <Cell>{row.market}</Cell>
                  {row.data.map((v, i) =>
                    hilightedStrike === tradeData.data.strikes[i] ||
                      hilightedExpiry === row.expiryInSeconds ? (
                      <HighlightedCell
                        key={`${row.expiration}${row.market}${tradeData.data.strikes[i]}`}
                      >
                        {v.iv || '-'}
                      </HighlightedCell>
                    ) : (
                      <ClickableCell
                        key={`${row.expiration}${row.market}${tradeData.data.strikes[i]}`}
                        onClick={() => {
                          setLocation(
                            `/deal/${params?.option}/${v.id}${fields.ivType === IVType.Sell ? '?sell=true' : ''
                            }`
                          )
                          mixpanel.track('click table cell', {
                            ivType: fields.ivType,
                            valueType: fields.valueType,
                            optionType: params?.option,
                            optionId: v.id
                          })
                        }}
                      >
                        {v.iv || '-'}
                      </ClickableCell>
                    )
                  )}
                </Row>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      ) : (
        <LoadingIndicator />
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: ${SPACE.MEDIUM} 30px;
`

const FilterContainer = styled.div`
  display: flex;
`

const TableContainer = styled.div`
  width: fit-content;
  max-width: 100%;
  overflow-x: scroll;
  border-radius: ${RADIUS.MEDIUM};
  border: solid 1px ${({ theme }) => theme.palette.border};
  border-bottom: none;
  margin-top: 20px;
`

const Table = styled.table`
  color: ${({ theme }) => theme.palette.onSurface};
`

const Row = styled.tr`
  display: flex;

  &:nth-child(odd) {
    > * {
      background-color: ${({ theme }) => theme.palette.background};
    }
  }
  &:first-child {
    > * {
      background-color: ${({ theme }) => theme.palette.secondary};
    }
  }
`

const Cell = styled.td.attrs({ highlight: false })`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  padding: 12px 0;

  border-bottom: solid 1px ${({ theme }) => theme.palette.border};
  &:not(:last-child) {
    border-right: solid 1px ${({ theme }) => theme.palette.border};
  }
`

const HighlightedCell = styled(Cell)`
  text-decoration: underline;
`

const ClickableCell = styled(Cell)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const HeadItemContainer = styled.div`
  margin-right: ${SPACE.SMALL};
  display: flex;
  align-items: center;
`

const HeadValue = styled(FormValue)`
  min-width: 60px;
  margin-left: ${SPACE.SMALL};
  padding: 0 12px;
`

const ToggleContainer = styled.div`
  margin-right: ${SPACE.MEDIUM};
`

export default TableView
