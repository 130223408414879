import { BigNumber } from 'ethers'
import React from 'react'
import styled from 'styled-components'
import { Button } from '../components/common/Button'
import { RADIUS, SPACE } from '../constants'
import { useLiquidateMutation } from '../hooks/query/liquidation'
import {
  useHelthFactor,
  usePoolStatus,
  usePoolVaultStatus,
  useVaultStatus
} from '../hooks/query/status'
import { toUnscaled } from '../utils/bn'

type Props = {
  page: number
}

const TraderVaults: React.FC<Props> = ({ page }) => {
  const vaultStatus = useVaultStatus(page)

  const liquidate = useLiquidateMutation()

  const onLiquidation = async (
    accountId: BigNumber,
    seriesId: number,
    liqAmount: BigNumber,
    maxFee: BigNumber
  ) => {
    await liquidate.mutateAsync({
      accountId,
      seriesId,
      liqAmount,
      maxFee
    })
  }

  return (<TableContainer>
    {vaultStatus.isSuccess ? (
      <Table>
        <thead>
          <HeaderRow>
            <Cell>AccountId</Cell>
            <Cell>ExpiryId</Cell>
            <Cell>SeriesId</Cell>
            <Cell>Size</Cell>
            <Cell>Collateral</Cell>
            <Cell>Liquidatable</Cell>
            <Cell>Button</Cell>
          </HeaderRow>
        </thead>
        <tbody>
          {vaultStatus.data.map((d, i) => (
            <Row key={i}>
              <Cell>{d.accountId.toString()}</Cell>
              <Cell>{d.expiryId.toString()}</Cell>
              <Cell>{d.seriesId.toString()}</Cell>
              <Cell>{d.amount.toString()}</Cell>
              <Cell>{toUnscaled(d.collateral, 6, 2)}</Cell>
              <Cell>{d.liquidatableAmount ? toUnscaled(d.liquidatableAmount, 6, 2) : 'N/A'}</Cell>
              <Cell><Button kind="primary"
                disabled={d.liquidatableAmount === null || d.liquidatableAmount.eq(0)}
                onClick={() => { onLiquidation(d.accountId, d.seriesId, d.liquidatableAmount, d.collateral) }}>liquidate</Button></Cell>
            </Row>
          ))}
        </tbody>
      </Table>
    ) : (
      <div>Loading...</div>
    )}
  </TableContainer>
  )
}

const VaultStatusView = () => {
  const poolVaultStatus = usePoolVaultStatus(6, 14)
  const poolStatus = usePoolStatus(6, 14)
  const healthFactor = useHelthFactor()

  const [currentPage, setPage] = React.useState(0);

  return (
    <Container>
      <SubTitle>Trader&apos;s Vaults</SubTitle>
      <PagenationArea>
        <Button kind="primary" size="small" onClick={() => { if (currentPage > 0) setPage(currentPage - 1) }}>Previous</Button>
        Page {currentPage}.
        <Button kind="primary" size="small" onClick={() => { setPage(currentPage + 1) }}>Next</Button>
        <TraderVaults page={currentPage}></TraderVaults>
      </PagenationArea>
      <SubTitle>Pool&apos;s Vaults</SubTitle>
      <TableContainer>
        {poolVaultStatus.isSuccess ? (
          <Table>
            <thead>
              <HeaderRow>
                <Cell>AccountId</Cell>
                <Cell>ExpiryId</Cell>
                <Cell>Collateral</Cell>
                <Cell>ETH exposure</Cell>
                <Cell>AAVE collateral</Cell>
                <Cell>Options&apos; delta</Cell>
                <Cell>Net delta</Cell>
                <Cell>Size</Cell>
                <Cell>IVMoves</Cell>
              </HeaderRow>
            </thead>
            <tbody>
              {poolVaultStatus.data.map((d, i) => (
                <Row key={i}>
                  <Cell>{d.accountId.toString()}</Cell>
                  <Cell>{d.expiryId.toString()}</Cell>
                  <Cell>{toUnscaled(d.collateral, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.hedgePosition, 8, 2)}</Cell>
                  <Cell>{toUnscaled(d.shortLiquidity, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.vaultDelta, 8, 2)}</Cell>
                  <Cell>{toUnscaled(d.hedgePosition.add(d.vaultDelta), 8, 2)}</Cell>
                  <Cell>{d.shortSize.join(',')}</Cell>
                  <Cell>{d.ivMove.join(',')}</Cell>
                </Row>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>Loading...</div>
        )}
      </TableContainer>

      <SubTitle>Pool Status</SubTitle>
      <TableContainer>
        {poolStatus.isSuccess ? (
          <Table>
            <thead>
              <HeaderRow>
                <Cell>TickId</Cell>
                <Cell>Balance</Cell>
                <Cell>Supply</Cell>
                <Cell>Unrealized PnL</Cell>
                <Cell>Last Balance</Cell>
                <Cell>Last Supply</Cell>
                <Cell>Cumulative Fee</Cell>
                <Cell>Locked Value</Cell>
              </HeaderRow>
            </thead>
            <tbody>
              {poolStatus.data.map((d, i) => (
                <Row key={i}>
                  <Cell>{d.tickId}</Cell>
                  <Cell>{toUnscaled(d.balance, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.supply, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.unrealizedPnL, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.lastBalance, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.lastSupply, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.cumulativeFee, 6, 2)}</Cell>
                  <Cell>{toUnscaled(d.collateralValue, 6, 2)}</Cell>
                </Row>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>Loading...</div>
        )}
      </TableContainer>

      <SubTitle>Health Factor</SubTitle>
      <div>{(healthFactor.isSuccess && healthFactor.data.healthFactor.lt('10000000000000000000')) && toUnscaled(healthFactor.data.healthFactor, 18)}</div>
    </Container>
  )
}

const Container = styled.div`
  padding: ${SPACE.MEDIUM} 30px;
`

const TableContainer = styled.div`
  width: fit-content;
  max-width: 100%;
  overflow-x: scroll;
  border-radius: ${RADIUS.MEDIUM};
  border: solid 1px ${({ theme }) => theme.palette.border};
  border-bottom: none;
  margin-top: 20px;
`

const Table = styled.table`
  color: ${({ theme }) => theme.palette.onSurface};
`

const HeaderRow = styled.tr`
  display: flex;

  background-color: ${({ theme }) => theme.palette.secondary};
`

const Row = styled.tr`
  display: flex;

  &:nth-child(odd) {
    > * {
      background-color: ${({ theme }) => theme.palette.background};
    }
  }
`

const Cell = styled.td.attrs({ highlight: false })`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  padding: 12px 0;

  border-bottom: solid 1px ${({ theme }) => theme.palette.border};
  &:not(:last-child) {
    border-right: solid 1px ${({ theme }) => theme.palette.border};
  }
`

const SubTitle = styled.h1`
  margin-top: ${SPACE.MEDIUM};
  padding: 0 12px;
`

const PagenationArea = styled.div`
  margin-top: ${SPACE.MEDIUM};
`

export default VaultStatusView
