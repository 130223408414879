import React from 'react'
import styled from 'styled-components'
import { FONT_SIZE, RADIUS, SPACE } from '../../constants'

type Props<T> = {
  options: { label: string; value: T }[]
  value: T
  onChange: (value: T) => void
}

const Toggle = <T extends unknown>({ options, value, onChange }: Props<T>) => {
  return (
    <Container>
      {options.map(option => (
        <Item
          selected={value === option.value}
          key={option.label}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Item>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.control};
  border-radius: ${RADIUS.LARGE};
`

const Item = styled.span<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.SMALL};
  background-color: ${({ theme }) => theme.palette.control};
  color: ${({ theme }) => theme.palette.onControl};
  border: none;
  border-radius: ${RADIUS.LARGE};
  padding: ${SPACE.SMALL} ${SPACE.MEDIUM};
  min-width: 40px;
  height: 24px;
  padding: ${SPACE.SMALL} ${SPACE.MEDIUM};
  ${({ selected, theme }) =>
    selected
      ? `
	background-color: ${theme.palette.primary};
	color: ${theme.palette.onPrimary};
	`
      : ''}
`

export default Toggle
