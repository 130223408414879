import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { ERC20__factory } from '../../typechain'
import { useChainId, useIsSupportedChain } from '../network'

export function useERC20BalanceQuery(address: string) {
  const { library, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()

  return useQuery(
    ['balance', account, address, chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!supportedChain) throw new Error('not supported chain')

      const contract = ERC20__factory.connect(address, library)
      return await contract.balanceOf(account)
    },

    {
      enabled: !!account && !!library
    }
  )
}

export function useEthBalanceQuery() {
  const { library, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()

  return useQuery(
    ['balance', account, chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!supportedChain) throw new Error('not supported chain')

      return await library.getBalance(account)
    },

    {
      enabled: !!account && !!library
    }
  )
}
