import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { FONT_SIZE, RADIUS, SPACE } from '../../constants'
import { injected } from '../../connectors'
import WalletIcon from '../../assets/wallet_icon_white.svg'
import mixpanel from '../../mixpanel'
import { usePrevious } from 'react-use'

const ConnectButton: React.FC = ({ children }) => {
  const { account, active, activate } = useWeb3React()
  const [activateClicked, setActivateClicked] = useState(false)
  const prevClicked = usePrevious(activateClicked)

  useEffect(() => {
    if (!prevClicked && activateClicked && active && account) {
      mixpanel.track('connect', {
        address: account,
        clicked: true
      })
    }
  }, [activateClicked, active]) // eslint-disable-line

  const onClick = async () => {
    await activate(injected)
    setActivateClicked(true)
  }

  if (active && account) {
    return (
      <Account>
        <Icon src={WalletIcon} />
        {account.slice(0, 6)}…{account.slice(38)}
      </Account>
    )
  }

  return (
    <Button onClick={onClick} type="button">
      {children ? children : 'Connect Wallet'}
    </Button>
  )
}

const Button = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.secondary};
  color: ${({ theme }) => theme.palette.onSurface};
  padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
  border: none;
  border-radius: ${RADIUS.ROUND};
  font-size: ${FONT_SIZE.SMALL};
`

const Account = styled.span`
  display: flex;
  background-color: ${({ theme }) => theme.palette.secondary};
  color: ${({ theme }) => theme.palette.onSurface};
  border: none;
  border-radius: ${RADIUS.ROUND};
  padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
  font-size: ${FONT_SIZE.SMALL};
`

const Icon = styled.img`
  margin-right: ${SPACE.SMALL};
`

export default ConnectButton
